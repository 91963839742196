import { AppState } from "./models";
import { ReducerAction } from "../models/reducer";
import AppStateProvider from "./appStateProvider";
import useAppState from "./selectors/useAppState";
import appState from "./appState";
import AppStoreSubscriptions from "./appStateSubscriptions";

appState.subscribe(AppStoreSubscriptions.getLocationFromStorageIfStateIsEmpty);
appState.subscribe(AppStoreSubscriptions.setLocationInStorageFromState);

appState.subscribe(AppStoreSubscriptions.getPinCodeFromStorageIfStateIsEmpty);
appState.subscribe(AppStoreSubscriptions.setPinCodeInStorageFromState);

appState.subscribe(AppStoreSubscriptions.getPrinterIpFromStorageIfStateIsEmpty);
appState.subscribe(AppStoreSubscriptions.setPrinterIpInStorageFromState);

appState.subscribe(AppStoreSubscriptions.getMasterPasswordResultFromStorageIfStateIsEmpty);
appState.subscribe(AppStoreSubscriptions.setMasterPasswordResultInStorageFromState);

export const getAppState: () => AppState = appState.getState;

export interface DispatchAppStateAction {
	(action: ReducerAction): void;
}

export const dispatchAppStateAction: DispatchAppStateAction = appState.dispatch;

export { AppStateProvider, useAppState };
