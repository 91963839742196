import { Order, OrderState } from "../models/domain";
import {
	ContentModalProps,
	LoadingModalProps,
	OrderListLoadingPageProps,
	OrderListWithOrdersPageProps,
} from "../components/props";
import { OrderButtonActions, resolveOrderProps } from "./resolveOrderProps";
import { resolveRefreshButton } from "./resolveButtonProps";
import { formatDate, resolveToday, sortOrdersByDate } from "./utils";
import { NormalizedState } from "../../../appState/models";

export enum OrderListPageState {
	IDLE = "IDLE",
	FETCHING = "FETCHING",
}

export enum OrderViewState {
	COLLAPSED = "COLLAPSED",
	FETCHING = "FETCHING",
	EXPANDED = "EXPANDED",
}

export type ModalState = LoadingModalProps | ContentModalProps | undefined;

interface OrderListState {
	pageState: OrderListPageState;
	modalState: ModalState;
	ordersState: NormalizedState<OrderViewState>;
	orders: Array<Order>;
}

interface OrderListActions {
	refreshOrders: () => void;
	toggleOrder: (oderId: number) => void;
	print: (orderId: number) => void;
	prepare: (orderId: number) => void;
	approve: (orderId: number) => void;
	openModalForCancel: (orderId: number) => void;
	openModalForCollect: (orderId: number) => void;
}

export default function toOrderListProps(
	state: OrderListState,
	actions: OrderListActions
): OrderListLoadingPageProps | OrderListWithOrdersPageProps {
	if (state.pageState === OrderListPageState.FETCHING) return new OrderListLoadingPageProps({});

	function createOrderButtonActions(orderId: number): OrderButtonActions {
		return {
			toggleDetails: () => actions.toggleOrder(orderId),
			prepare: () => actions.prepare(orderId),
			cancel: () => actions.openModalForCancel(orderId),
			approve: () => actions.approve(orderId),
			collect: () => actions.openModalForCollect(orderId),
			print: () => actions.print(orderId),
		};
	}

	const sortedOrders = sortOrdersByDate(state.orders);

	return new OrderListWithOrdersPageProps({
		refreshButton: resolveRefreshButton(actions.refreshOrders),
		title: "Kundenbestellungen",
		description:
			"Hier werden alle offenen Kundenbestellungen angezeigt. Es können Bestellungen bearbeitet werden und die Details zur Bestellung abgerufen werden.",
		orderSections: Object.keys(sortedOrders)
			.sort()
			.map((date: string) => {
				const ordersForDate = sortedOrders[date];
				return {
					title: resolveToday(date) + formatDate(date),
					description: "",
					orders: [
						...ordersForDate.filter((order) => order.state === OrderState.PENDING),
						...ordersForDate.filter((order) => order.state === OrderState.APPROVED),
						...ordersForDate.filter((order) => order.state === OrderState.PREPARED),
					].map((order) => {
						return resolveOrderProps(
							order,
							createOrderButtonActions(order.id),
							state.ordersState[order.id]
						);
					}),
				};
			}),
		checkActionModal: state.modalState,
	});
}
