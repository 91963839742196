import { ReducerAction } from "../../../models/reducer";
import { SettingsActionTypes } from "./actionTypes";
import { Code } from "../../../authentication/models/code";
import { IPv4 } from "../models/ipv4";
import { LocationInState } from "./state";

interface SettingsReducerAction<Payload> extends ReducerAction<SettingsActionTypes, Payload> {}

export interface SetLocation extends SettingsReducerAction<{ location: LocationInState }> {}

export interface SetPinCode extends SettingsReducerAction<{ pinCode: string }> {}

export interface SetPrinterIp extends SettingsReducerAction<{ printerIp: string }> {}

export interface SetMasterPasswordResult extends SettingsReducerAction<{ masterPasswordResult: string }> {}

export function setLocation(location: LocationInState): SetLocation {
	return {
		type: SettingsActionTypes.SET_LOCATION,
		payload: {
			location: location,
		},
	};
}

export function setPinCode(pinCode: Code): SetPinCode {
	return {
		type: SettingsActionTypes.SET_PIN_CODE,
		payload: {
			pinCode: pinCode.toString(),
		},
	};
}

export function setPrinterIp(printerIp: IPv4): SetPrinterIp {
	return {
		type: SettingsActionTypes.SET_PRINTER_IP,
		payload: {
			printerIp: printerIp.toString(),
		},
	};
}

export function setMasterPasswordResult(masterPasswordResult: string): SetMasterPasswordResult {
	return {
		type: SettingsActionTypes.SET_MASTER_PASSWORD_RESULT,
		payload: {
			masterPasswordResult: masterPasswordResult,
		},
	};
}
