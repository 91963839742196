import { GetAll, GetAllFilteredBy, GetById, NinoxRoutes, ResolveTableId, Table, UpdateOrCreateRecords } from "./models";
import { Api } from "../data/models";

interface NinoxRouteDependencies {
	api: Api;
	ninoxTeamId: string;
	ninoxDatabaseId: string;
}

export function createNinoxRoutes<TableType>(dependencies: NinoxRouteDependencies): NinoxRoutes<TableType> {
	const resolveTableId: ResolveTableId<TableType> = (tableName) => {
		return dependencies.api
			.get<Array<Table>>(`/teams/${dependencies.ninoxTeamId}/databases/${dependencies.ninoxDatabaseId}/tables`)
			.then((allTables) => {
				return String(allTables.find((table) => table.name === String(tableName))!.id);
			});
	};

	const getBasePath = (tableName: TableType): Promise<string> => {
		return resolveTableId(tableName).then((ninoxTableId) => {
			return `/teams/${dependencies.ninoxTeamId}/databases/${dependencies.ninoxDatabaseId}/tables/${ninoxTableId}`;
		});
	};

	const getAll: GetAll<TableType> = async (table) => {
		return getBasePath(table).then((basePath) => {
			return dependencies.api.get(`${basePath}/records`);
		});
	};

	const getAllFilteredBy: GetAllFilteredBy<TableType> = async (table, filters) => {
		return getBasePath(table).then((basePath) => {
			const parameter = { filters: JSON.stringify({ fields: { ...filters } }) };
			return dependencies.api.get(`${basePath}/records`, parameter);
		});
	};

	const getById: GetById<TableType> = (table, id) => {
		return getBasePath(table).then((basePath) => {
			return dependencies.api.get(`${basePath}/records/${id}`);
		});
	};

	const updateOrCreateRecords: UpdateOrCreateRecords<TableType> = (table, records) => {
		return getBasePath(table).then((basePath) => {
			return dependencies.api.post(`${basePath}/records`, records);
		});
	};

	return {
		getAll: getAll,
		getAllFilteredBy: getAllFilteredBy,
		getById: getById,
		updateOrCreateRecords: updateOrCreateRecords,
	};
}
