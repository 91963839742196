import { Code } from "../../../authentication/models/code";
import useSettingsState from "../../settings/selectors/useSettingsState";
import React from "react";
import authenticationActions from "../../../authentication/actions";

export default function useExpectedPinCodeFromSettings(): Code {
	const settingsState = useSettingsState();
	const emptyExpectedPinCode = new Code(settingsState.pinCode!).nullify();

	React.useEffect(() => {
		authenticationActions.setPinCode(emptyExpectedPinCode);
		// eslint-disable-next-line
	}, []);

	return emptyExpectedPinCode;
}
