import React from "react";
import { TextInputProps } from "../models/props";
import { PinInput, PinInputProps } from "./pinInput";
import { focusElement } from "../utils/view";

export type CodeInputProps = ValidInputProps | InvalidInputProps;

export function CodeInput(props: CodeInputProps) {
	function resolveValidation() {
		if (!(props instanceof InvalidInputProps)) return null;
		return <p>{props.validation.message}</p>;
	}

	React.useLayoutEffect(() => {
		focusElement(props.codeElements[0].fieldId || "");
		// eslint-disable-next-line
	}, []);

	return (
		<fieldset
			className="grid gap-2"
			style={{ gridTemplateColumns: "repeat(" + props.codeElements.length * 2 + ", 1fr)" }}
		>
			{props.codeElements.map((pinInputProps, index) => {
				return <PinInput key={index + 1} {...pinInputProps} />;
			})}
			{resolveValidation()}
		</fieldset>
	);
}

export interface CodeElementProps extends PinInputProps {}

export interface ValidationProps {
	message: string;
}

export class BaseCodeInputProps {
	codeElements: Array<CodeElementProps>;

	constructor(codeElementsAsTextInputProps: Array<TextInputProps>) {
		this.codeElements = codeElementsAsTextInputProps.map((textInputProps): CodeElementProps => {
			return {
				...textInputProps,
				pinCodeLength: codeElementsAsTextInputProps.length,
			};
		});
	}
}

export class ValidInputProps extends BaseCodeInputProps {}

export class InvalidInputProps extends BaseCodeInputProps {
	validation: ValidationProps;

	constructor(codeElements: Array<CodeElementProps>, validation: ValidationProps) {
		super(codeElements);
		this.validation = validation;
	}
}
