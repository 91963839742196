import { v5 as uuidv5 } from "uuid";
import environment from "../../../misc/environment";

export class MasterPassword {
	private static readonly _privateKey: string = environment.REACT_APP_MASTER_PW_PRIVATE_KEY;
	private static readonly _publicKey: string = environment.REACT_APP_MASTER_PW_PUBLIC_KEY;
	private static readonly _result: string = uuidv5(
		environment.REACT_APP_MASTER_PW_PUBLIC_KEY,
		environment.REACT_APP_MASTER_PW_PRIVATE_KEY
	);

	public static validateMasterPasswordResult(masterPasswordResult: string): boolean {
		return masterPasswordResult === MasterPassword._result;
	}

	public static validatePublicKey(publicKey: string): boolean {
		return (
			MasterPassword.createMasterPasswordResult(publicKey, MasterPassword._privateKey) === MasterPassword._result
		);
	}

	public static createMasterPasswordResultFromPublicKey = (publicKey: string): string => {
		return uuidv5(publicKey, MasterPassword._privateKey);
	};

	public static createMasterPasswordResult = (publicKey: string, privateKey: string): string => {
		return uuidv5(publicKey, privateKey);
	};
}
