import React from "react";
import ReactDOM from "react-dom";
import { ContentModalProps, LoadingModalProps } from "./props";
import CheckOrderActionModal from "./checkOrderActionModal";

export function resolveModal(props: LoadingModalProps | ContentModalProps) {
	if (props instanceof LoadingModalProps) return <LoadingModal {...props} />;
	return <ContentModal {...props} />;
}

export function LoadingModal(props: LoadingModalProps) {
	return ReactDOM.createPortal(<CheckOrderActionModal {...props} />, document.getElementById("root")!);
}

export function ContentModal(props: ContentModalProps) {
	return ReactDOM.createPortal(<CheckOrderActionModal {...props} />, document.getElementById("root")!);
}
