import { OrderButtonProps } from "./props";
import { ButtonProps, IconButtonProps } from "../../../models/props";
import React from "react";
import { Icon } from "../../../components/icons";

export function resolveButtons(buttons: OrderButtonProps) {
	return Object.entries(buttons).map(([buttonName, buttonProps], index) => {
		if (buttonProps === undefined) return null;

		switch (buttonName) {
			case "toggleDetails":
				return <DetailsButton key={index + 1} {...buttonProps} />;
			case "approve":
				return <ApproveButton key={index + 1} {...buttonProps} />;
			case "prepare":
				return <PrepareButton key={index + 1} {...buttonProps} />;
			case "collect":
				return <CollectButton key={index + 1} {...buttonProps} />;
			case "cancel":
				return <CancelButton key={index + 1} {...buttonProps} />;
			case "print":
				return <PrintButton key={index + 1} {...(buttonProps as IconButtonProps)} />;
			default:
				return null;
		}
	});
}

export function RefreshButton(props: IconButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-2 btn-md">
			<span className="sr-only">{props.title}</span>
			<Icon icon={props.icon} className="w-6 h-6" />
		</button>
	);
}

export function DetailsButton(props: IconButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-2 btn-md space-x-2">
			<Icon icon={props.icon} className="w-6 h-6" />
			<span className="sr-only md:not-sr-only">{props.title}</span>
		</button>
	);
}

export function ApproveButton(props: ButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-1 btn-md">
			<span>{props.title}</span>
		</button>
	);
}

export function PrepareButton(props: ButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-1 btn-md">
			<span>{props.title}</span>
		</button>
	);
}

export function CollectButton(props: ButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-1 btn-md">
			<span>{props.title}</span>
		</button>
	);
}

export function CancelButton(props: IconButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-danger btn-md space-x-2">
			<Icon icon={props.icon} className="w-6 h-6" />
			<span className="sr-only md:not-sr-only">{props.title}</span>
		</button>
	);
}

export function PrintButton(props: IconButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-2 btn-md">
			<span className="sr-only">{props.title}</span>
			<Icon icon={props.icon} className="w-6 h-6" />
		</button>
	);
}
