import React from "react";
import { ButtonPropsType } from "../../../models/props";
import { ContentModalProps, OrderListLoadingPageProps, OrderListWithOrdersPageProps } from "../components/props";
import useOrderListState from "./useOrderListState";
import toOrderListProps, { ModalState } from "./toOrderListProps";

export default function useOrderListProps(): OrderListLoadingPageProps | OrderListWithOrdersPageProps {
	const ordersListState = useOrderListState();
	const [modalState, setModalState] = React.useState<ModalState>(undefined);

	return toOrderListProps(
		{
			pageState: ordersListState.pageState,
			modalState: modalState,
			ordersState: ordersListState.ordersViewStates,
			orders: ordersListState.orders,
		},
		{
			toggleOrder: ordersListState.toggleOrder,
			refreshOrders: ordersListState.fetchOrders,
			print: ordersListState.printOrder,
			prepare: ordersListState.prepareOrder,
			approve: ordersListState.approveOrder,
			openModalForCancel: (orderId: number) =>
				setModalState(
					new ContentModalProps({
						title: "Bestellung Ablehnen",
						message: "",
						buttons: {
							cancel: {
								title: "Nein",
								type: ButtonPropsType.SEC,
								onSelect: () => setModalState(undefined),
							},
							approve: {
								title: "Ja",
								type: ButtonPropsType.PRIME,
								onSelect: () =>
									ordersListState.cancelOrder(orderId).then(() => setModalState(undefined)),
							},
						},
					})
				),
			openModalForCollect: (orderId: number) =>
				setModalState(
					new ContentModalProps({
						title: "Bestellung Als Abgeholt Markieren",
						message: "",
						buttons: {
							cancel: {
								title: "Nein",
								type: ButtonPropsType.SEC,
								onSelect: () => setModalState(undefined),
							},
							approve: {
								title: "Ja",
								type: ButtonPropsType.PRIME,
								onSelect: () =>
									ordersListState.collectOrder(orderId).then(() => setModalState(undefined)),
							},
						},
					})
				),
		}
	);
}
