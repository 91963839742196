import { ButtonProps, ButtonPropsType, IconButtonProps } from "../../../models/props";
import { IconType } from "../../../components/icons";
import { OrderState } from "../models/domain";
import { OrderButtonProps } from "../components/props";
import { OrderButtonActions } from "./resolveOrderProps";

export function resolveOrderButtons(orderState: OrderState, actions: OrderButtonActions): OrderButtonProps {
	switch (orderState) {
		case OrderState.PENDING:
			return {
				toggleDetails: resolveDetailsButtonProps(actions.toggleDetails),
				approve: resolveApproveButtonProps(actions.approve),
				cancel: resolveCancelButtonProps(actions.cancel),
				print: resolvePrintButtonProps(actions.print),
			};
		case OrderState.APPROVED:
			return {
				toggleDetails: resolveDetailsButtonProps(actions.toggleDetails),
				prepare: resolvePrepareButtonProps(actions.prepare),
				cancel: resolveCancelButtonProps(actions.cancel),
				print: resolvePrintButtonProps(actions.print),
			};
		case OrderState.PREPARED:
			return {
				toggleDetails: resolveDetailsButtonProps(actions.toggleDetails),
				collect: resolveCollectButtonProps(actions.collect),
				cancel: resolveCancelButtonProps(actions.cancel),
				print: resolvePrintButtonProps(actions.print),
			};
		default:
			return {
				toggleDetails: resolveDetailsButtonProps(actions.toggleDetails),
				print: resolvePrintButtonProps(actions.print),
			};
	}
}

export function resolveRefreshButton(clickHandler: () => void): IconButtonProps {
	return {
		type: ButtonPropsType.SEC,
		icon: IconType.REFRESH,
		title: "Refresh",
		onSelect: clickHandler,
	};
}

export function resolveDetailsButtonProps(clickHandler: () => void): IconButtonProps {
	return {
		type: ButtonPropsType.SEC,
		icon: IconType.INFO,
		title: "Details",
		onSelect: clickHandler,
	};
}

export function resolveApproveButtonProps(clickHandler: () => void): ButtonProps {
	return {
		type: ButtonPropsType.PRIME,
		title: "Annehmen",
		onSelect: clickHandler,
	};
}

export function resolvePrepareButtonProps(clickHandler: () => void): ButtonProps {
	return {
		type: ButtonPropsType.PRIME,
		title: "Vorbereiten",
		onSelect: clickHandler,
	};
}

export function resolveCollectButtonProps(clickHandler: () => void): ButtonProps {
	return {
		type: ButtonPropsType.PRIME,
		title: "Abgeholt",
		onSelect: clickHandler,
	};
}

export function resolveCancelButtonProps(clickHandler: () => void): IconButtonProps {
	return {
		type: ButtonPropsType.DANGER,
		icon: IconType.CLOSE,
		title: "Ablehnen",
		onSelect: clickHandler,
	};
}

export function resolvePrintButtonProps(clickHandler: () => void): IconButtonProps {
	return {
		type: ButtonPropsType.SEC,
		icon: IconType.PRINT,
		title: "Bestellung Drucken",
		onSelect: clickHandler,
	};
}
