import React, { PropsWithChildren } from "react";
import { OrderListLoadingPageProps, OrderListPageContentProps, OrderListWithOrdersPageProps } from "./props";
import { OrderSection } from "./orderSection";
import { RefreshButton } from "./resolveButtons";
import { resolveModal } from "./resolveModal";

export default function OrderList(props: OrderListLoadingPageProps | OrderListWithOrdersPageProps) {
	if (props instanceof OrderListLoadingPageProps) {
		return <LoadingPage />;
	} else {
		return <OrderListContentPage {...props.content} />;
	}
}

export function OrderListContainer(props: PropsWithChildren<{}>) {
	return (
		<div className="h-full w-full max-w-4xl mx-auto">
			<div className="p-4 sm:m-4 sm:rounded-lg sm:shadow-md bg-white dark:bg-gray-900 flex items-center justify-center">
				{props.children}
			</div>
		</div>
	);
}

export function LoadingPage() {
	return (
		<OrderListContainer>
			<div className="h-full w-full max-w-4xl p-4 flex items-center justify-center">
				<div className="w-full space-y-4 animate-pulse">
					<div className="h-12 w-2/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-24 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="space-y-2">
						<div className="h-12 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
						<div className="h-12 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
						<div className="h-12 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
					</div>
				</div>
			</div>
		</OrderListContainer>
	);
}

export function OrderListContentPage(props: OrderListPageContentProps) {
	return (
		<OrderListContainer>
			{props.checkActionModal && resolveModal(props.checkActionModal)}
			<div className="w-full p-4 max-w-4xl mx-auto text-gray-800 dark:text-gray-200">
				<div className="flex justify-between">
					<h2 className="flex-grow my-8 text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-serif">
						{props.title}
					</h2>
					<div className="my-auto">
						<RefreshButton {...props.refreshButton} />
					</div>
				</div>
				<p className="my-4 text-base text-gray-600 dark:text-gray-400">{props.description}</p>
				<div className="mt-12 space-y-12">
					{props.orderSections.map((orderSection, index) => {
						return <OrderSection key={index + 1} {...orderSection} />;
					})}
				</div>
			</div>
		</OrderListContainer>
	);
}
