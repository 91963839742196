import React from "react";
import { IconProps } from "../models/domain";

export default function Tripadvisor(props: IconProps) {
	return (
		<svg
			{...props}
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 512 512"
			fill="currentColor"
			stroke="none"
		>
			<path
				d="M128,159.234H16c-7.136,0-13.376,4.704-15.36,11.552c-1.984,6.88,0.8,14.208,6.848,17.984
				c0.128,0.096,11.072,7.232,19.552,20.224C9.536,231.234,0,258.562,0,287.234c0,70.592,57.408,128,128,128s128-57.408,128-128
				S198.592,159.234,128,159.234z M128,383.234c-52.928,0-96-43.072-96-96c0-24.384,9.248-47.52,25.984-65.12
				c4.512-4.736,5.696-11.68,3.008-17.632c-2.176-4.8-4.672-9.248-7.328-13.248H128c52.928,0,96,43.072,96,96
				C224,340.162,180.928,383.234,128,383.234z"
			/>
			<path
				d="M301.536,338.306c-7.136-5.152-17.12-3.68-22.368,3.552l-22.336,30.784L232.48,341.41
				c-5.44-6.976-15.52-8.256-22.464-2.784c-6.944,5.44-8.192,15.488-2.752,22.464l37.44,48c3.04,3.872,7.68,6.144,12.608,6.144
				c0.096,0,0.192,0,0.288,0c5.024-0.096,9.728-2.528,12.64-6.592l34.816-48C310.24,353.506,308.704,343.49,301.536,338.306z"
			/>
			<path
				d="M426.784,163.426c-97.344-88.864-244.224-88.896-341.568,0c-6.528,5.952-7.008,16.064-1.024,22.592
				c5.952,6.56,16.064,6.976,22.592,1.024c85.088-77.664,213.376-77.664,298.432,0c3.072,2.816,6.944,4.192,10.784,4.192
				c4.352,0,8.672-1.76,11.808-5.216C433.792,179.49,433.312,169.378,426.784,163.426z"
			/>
			<path
				d="M128,223.234c-35.296,0-64,28.704-64,64c0,35.296,28.704,64,64,64s64-28.704,64-64
				C192,251.938,163.296,223.234,128,223.234z M128,319.234c-17.632,0-32-14.336-32-32c0-17.632,14.368-32,32-32s32,14.368,32,32
				C160,304.898,145.632,319.234,128,319.234z"
			/>
			<path
				d="M128.64,271.234h-0.32c-8.832,0-15.84,7.168-15.84,16c0,8.832,7.328,16,16.16,16c8.832,0,16-7.168,16-16
				C144.64,278.402,137.472,271.234,128.64,271.234z"
			/>
			<path
				d="M484.928,208.994c8.48-12.96,19.488-20.192,19.584-20.224c6.048-3.808,8.832-11.136,6.848-17.984
				c-1.952-6.848-8.224-11.552-15.36-11.552H384c-70.592,0-128,57.408-128,128s57.408,128,128,128s128-57.408,128-128
				C512,258.594,502.464,231.234,484.928,208.994z M384,383.234c-52.928,0-96-43.072-96-96c0-52.928,43.072-96,96-96h74.336
				c-2.656,4-5.152,8.448-7.328,13.248c-2.656,5.952-1.504,12.928,3.008,17.632C470.784,239.746,480,262.85,480,287.234
				C480,340.162,436.928,383.234,384,383.234z"
			/>
			<path
				d="M384,223.234c-35.296,0-64,28.704-64,64c0,35.296,28.704,64,64,64c35.296,0,64-28.704,64-64
				C448,251.938,419.296,223.234,384,223.234z M384,319.234c-17.664,0-32-14.336-32-32c0-17.632,14.336-32,32-32s32,14.368,32,32
				C416,304.898,401.664,319.234,384,319.234z"
			/>
			<path
				d="M384.64,271.234h-0.32c-8.832,0-15.84,7.168-15.84,16c0,8.832,7.36,16,16.16,16c8.832,0,16-7.168,16-16
				C400.64,278.402,393.472,271.234,384.64,271.234z"
			/>
		</svg>
	);
}
