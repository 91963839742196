import { Order } from "../models/domain";

export function formatDate(dateLike: string): string {
	const daysOfTheWeek = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"];
	const months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const date = new Date(dateLike);
	return `${daysOfTheWeek[date.getDay()]}, ${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export function formatShortDate(dateLike: string): string {
	const daysOfTheWeek = ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"];
	const months = ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
	const date = new Date(dateLike);
	return `${daysOfTheWeek[date.getDay()]}, ${date.getDate()}. ${months[date.getMonth()]} ${date.getFullYear()}`;
}

export function sortOrdersByDate(orders: Array<Order>): { [key: string]: Array<Order> } {
	return orders.reduce((sortedOrders, order) => {
		return {
			...sortedOrders,
			[order.meta.date]: [...(sortedOrders[order.meta.date] || []), order],
		};
	}, {} as { [key: string]: Array<Order> });
}

export function resolveToday(dateLike: string): string {
	const isToday = formatToDataBaseDate(new Date()) === formatToDataBaseDate(new Date(dateLike));
	return isToday ? "Heute: " : "";
}

function formatToDataBaseDate(date: Date): string {
	return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}
