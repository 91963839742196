import React from "react";
import { LoginPageLoadingProps, LoginPageLoginProps, LoginProps, SubmittableInputProps } from "./props";
import { CodeInput } from "../../../components/codeInput";
import { LoadingPage } from "../../../commonPages/loading";

export default function LoginPage(props: LoginPageLoadingProps | LoginPageLoginProps) {
	if (props instanceof LoginPageLoadingProps) {
		return LoadingPage();
	} else {
		return LoginFormPage(props.content);
	}
}

export function LoginFormPage(props: LoginProps) {
	function resolveSubmitButton() {
		if (!(props.code instanceof SubmittableInputProps)) return null;
		return (
			<button
				id="login-button"
				type="button"
				onClick={props.code.loginButton.onSelect}
				onKeyUp={(event: any) => {
					// @ts-ignore
					if (event.key === "Enter") props.code.loginButton.onSelect();
				}}
				className="btn-1 btn-lg"
			>
				{props.code.loginButton.title}
			</button>
		);
	}

	return (
		<div className="h-full w-full bg-white dark:bg-black ptn-light dark:ptn-dark flex justify-center items-center">
			<div className="h-auto w-min pt-24 pb-16 px-8 rounded-3xl bg-gray-100 dark:bg-gray-900 flex flex-col justify-center items-center">
				<h1 className="text-6xl mb-8 text-gray-800 dark:text-gray-200">
					<span>{props.title}</span>
					<span className="font-bold">{props.subTitle}</span>
				</h1>
				<p className="my-4 text-sm text-center text-gray-400 dark:text-gray-600">{props.description}</p>
				<form className="flex flex-col justify-center items-center space-y-4">
					<CodeInput {...props.code} />
					{props.errorMessage && <p className="text-red-600 text-center">{props.errorMessage}</p>}
					{resolveSubmitButton()}
				</form>
			</div>
		</div>
	);
}
