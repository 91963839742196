import { OrdersState } from "./state";
import { Reducer } from "../../../models/reducer";
import { OrdersActionTypes } from "./actionTypes";
import { Order } from "../models/domain";
import { OrdersReducerActions } from "./reducerActions";

export function createOrdersState(): OrdersState {
	return {
		orders: [],
	};
}

interface OrdersReducer<Action = any> extends Reducer<OrdersState, Action> {}

export const ordersReducer: OrdersReducer = (state = createOrdersState(), action) => {
	switch (action.type) {
		case OrdersActionTypes.SET_ORDERS:
			return handleSetOrders(state, action);
		case OrdersActionTypes.SET_ORDER:
			return handleSetOrder(state, action);
		case OrdersActionTypes.SET_ORDER_STATE:
			return handleSetOrderState(state, action);
		default:
			return state;
	}
};

const handleSetOrders: OrdersReducer<OrdersReducerActions.SetOrders> = (state, action) => {
	return {
		...state,
		orders: [...action.payload.orders],
	};
};

const handleSetOrder: OrdersReducer<OrdersReducerActions.SetOrder> = (state, action) => {
	return {
		...state,
		orders: [
			...state.orders.filter((orderInState) => {
				return orderInState.id !== action.payload.order.id;
			}),
			new Order({ ...action.payload.order }),
		],
	};
};

const handleSetOrderState: OrdersReducer<OrdersReducerActions.SetOrderState> = (state, action) => {
	return {
		...state,
		orders: [
			...state.orders.map((orderInState) => {
				if (orderInState.id === action.payload.orderId) {
					return new Order({
						...orderInState,
						state: action.payload.orderState,
					});
				} else {
					return orderInState;
				}
			}),
		],
	};
};
