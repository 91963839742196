import { ReducerAction } from "../../../models/reducer";
import { PrinterNetworkState } from "./state";

export enum PrinterActionTypes {
	SET_PRINTER_MENU_STATE = "PRINTER/SET_PRINTER_MENU_STATE",
	SET_PRINTER_NETWORK_STATE = "PRINTER/SET_PRINTER_NETWORK_STATE",
	SET_PRINTER_PAPER_STATE = "PRINTER/SET_PRINTER_PAPER_STATE",
}

interface PrinterReducerAction<Payload> extends ReducerAction<PrinterActionTypes, Payload> {}

export interface SetPrinterNetworkState extends PrinterReducerAction<{ networkState: PrinterNetworkState }> {}

export function setPrinterNetworkState(networkState: PrinterNetworkState): SetPrinterNetworkState {
	return {
		type: PrinterActionTypes.SET_PRINTER_NETWORK_STATE,
		payload: {
			networkState: networkState,
		},
	};
}
