export function PrinterRequestInterceptor(request: any): any {
	const parameters = {
		devid: "local_printer",
		timeout: "60000",
	};
	const headers = {
		"Content-Type": "application/xml",
		"If-Modified-Since": "Thu, 01 Jan 1970 00:00:00 GMT",
		SOAPAction: "",
	};
	return {
		...request,
		headers: headers,
		params: parameters,
	};
}
