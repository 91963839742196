import React from "react";
import { AuthenticationStateType } from "./authentication/reducer/state";
import PageWrapper from "./components/pageWrapper";
import OrdersPage from "./features/orders/pages/ordersPage";
import useAuthenticationState from "./authentication/selectors/useAuthenticationState";
import LoginPage from "./features/login/pages/loginPage";

export default function PinProtectedApplication() {
	const authenticationState = useAuthenticationState();
	return <>{!(authenticationState.type === AuthenticationStateType.VALID) ? <LoginPage /> : <Application />}</>;
}

export function Application() {
	return (
		<PageWrapper>
			<OrdersPage />
		</PageWrapper>
	);
}
