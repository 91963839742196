import { IconProps, IconType } from "./models/domain";
import { resolveIcon } from "./utils";
import React from "react";

interface Props extends IconProps {
	icon: IconType;
}

export default function Icon(props: Props) {
	const { icon, ...iconProps } = props;
	const ResolvedIcon = resolveIcon(icon);
	return <ResolvedIcon {...iconProps} />;
}
