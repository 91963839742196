import { Pin } from "./pin";

export class Code {
	private readonly _value: string;

	constructor(codeLike: string) {
		if (Code.isCode(codeLike)) throw new Code.InvalidCodeError(codeLike);
		this._value = codeLike;
	}

	toString() {
		return this._value;
	}

	public static InvalidCodeError = class extends Error {
		constructor(codeLike: string) {
			super(`given code: "${codeLike}" is invalid. code has to be a set of 4 to 8 numbers[0-9].`);
		}
	};

	public static isCode(codeLike: string): boolean {
		return !/^\d{4,}$/.test(codeLike);
	}

	public static fromPins(pins: Array<Pin>): Code {
		return new Code(pins.map((pin) => pin.toString()).join(""));
	}

	public sameAs(code: Code): boolean {
		return this.toString() === code.toString();
	}

	public asPins(): Array<Pin> {
		return Array.from(this._value).map((pinLike: string) => new Pin(pinLike));
	}

	public nullify(): Code {
		return new Code(Array.from(this._value).fill("0").join(""));
	}
}
