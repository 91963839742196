export enum OrderState {
	LOADING = "LOADING",
	PENDING = "PENDING",
	APPROVED = "APPROVED",
	PREPARED = "PREPARED",
	COLLECTED = "COLLECTED",
	CANCELED = "CANCELED",
}

export interface CreateOrder {
	id: number;
	state: OrderState;
	products: Array<ProductInOrder>;
	meta: {
		pickUpId: string;
		date: string;
		time: string;
		customer: {
			name: string;
			phone?: string;
			email?: string;
		};
		notes?: string;
	};
	price: number;
}

export class Order {
	id: number;
	state: OrderState;
	products: Array<ProductInOrder>;
	meta: {
		pickUpId: string;
		date: string;
		time: string;
		customer: {
			name: string;
			phone?: string;
			email?: string;
		};
		notes: string;
	};
	price: number;
	constructor(request: CreateOrder) {
		this.id = request.id;
		this.state = request.state;
		this.products = request.products;
		this.meta = {
			...request.meta,
			notes: request.meta.notes || "",
		};
		this.price = request.price;
	}
}

interface CreateProduct {
	id: number;
	name: string;
	price: number;
}

export class Product {
	id: number;
	name: string;
	price: number;
	constructor(request: CreateProduct) {
		this.id = request.id;
		this.name = request.name;
		this.price = request.price;
	}
}

interface CreateProductInOrder extends CreateProduct {
	amount?: number;
}

export class ProductInOrder extends Product {
	amount: number;
	constructor(request: CreateProductInOrder) {
		super(request);
		this.amount = request.amount || 1;
	}
}
