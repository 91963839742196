import * as redux from "redux";
import rootReducer from "./rootReducer";
import * as reduxDevTools from "redux-devtools-extension";
import environment from "../misc/environment";

export function createAppState() {
	return redux.createStore(rootReducer, createReduxMiddleWare());
}

export default createAppState();

function createReduxMiddleWare() {
	if (environment.NODE_ENV === "development") {
		return reduxDevTools.composeWithDevTools();
	} else {
		return undefined;
	}
}
