import { ReducerAction } from "../../../models/reducer";
import { OrdersActionTypes, ProductsActionTypes } from "./actionTypes";
import { Order, OrderState, ProductInOrder } from "../models/domain";

export namespace OrdersReducerActions {
	interface OrdersReducerAction<Payload> extends ReducerAction<OrdersActionTypes, Payload> {}

	export interface SetOrders extends OrdersReducerAction<{ orders: Array<Order> }> {}
	export interface SetOrder extends OrdersReducerAction<{ order: Order }> {}
	export interface SetOrderState extends OrdersReducerAction<{ orderId: number; orderState: OrderState }> {}
}

export const ordersReducerActions = {
	setOrders: function (orders: Array<Order>): OrdersReducerActions.SetOrders {
		return {
			type: OrdersActionTypes.SET_ORDERS,
			payload: {
				orders: orders,
			},
		};
	},
	setOrder: function (order: Order): OrdersReducerActions.SetOrder {
		return {
			type: OrdersActionTypes.SET_ORDER,
			payload: {
				order: order,
			},
		};
	},
	setOrderState: function (orderId: number, orderState: OrderState): OrdersReducerActions.SetOrderState {
		return {
			type: OrdersActionTypes.SET_ORDER_STATE,
			payload: {
				orderId: orderId,
				orderState: orderState,
			},
		};
	},
};

export namespace ProductsReducerActions {
	interface ProductsReducerAction<Payload> extends ReducerAction<ProductsActionTypes, Payload> {}

	export interface SetProducts extends ProductsReducerAction<{ orderId: number; products: Array<ProductInOrder> }> {}
}

export const productsReducerActions = {
	setProducts: function (orderId: number, products: Array<ProductInOrder>): ProductsReducerActions.SetProducts {
		return {
			type: ProductsActionTypes.SET_PRODUCTS,
			payload: {
				orderId: orderId,
				products: products,
			},
		};
	},
};
