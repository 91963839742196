import React from "react";
import { PageFooterProps } from "./props";
import Link from "../../link";

export function PageFooter(props: PageFooterProps) {
	return (
		<footer className="bg-white dark:bg-black">
			<div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
				<p className="mt-8 mx-auto w-1/2 md:w-1/3 xl:w-1/4 text-center text-gray-400 text-xs">
					<strong>Impressum: </strong>
					{props.legal}
				</p>
				<p className="mt-8 text-center text-base text-gray-400">{props.copyright}</p>
			</div>
			<Link to="https://aricma.com" className="outline-none">
				<div className="pattern-aricma py-6 opacity-90 dark:opacity-100">
					<p className="font-aricma text-white dark:text-gray-300 space-x-2 flex items-center justify-center">
						<svg className="w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
							<path
								fillRule="evenodd"
								d="M12.316 3.051a1 1 0 01.633 1.265l-4 12a1 1 0 11-1.898-.632l4-12a1 1 0 011.265-.633zM5.707 6.293a1 1 0 010 1.414L3.414 10l2.293 2.293a1 1 0 11-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zm8.586 0a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 11-1.414-1.414L16.586 10l-2.293-2.293a1 1 0 010-1.414z"
								clipRule="evenodd"
							/>
						</svg>
						<span className="text-sm">by aricma</span>
					</p>
				</div>
			</Link>
		</footer>
	);
}

export default PageFooter;
