import { InvalidInputProps, ValidInputProps } from "../../../components/codeInput";
import { ButtonProps, TextInputProps } from "../../../models/props";

class LoginPageProps<ContentProps> {
	content: ContentProps;
	constructor(content: ContentProps) {
		this.content = content;
	}
}

export class LoginPageLoadingProps extends LoginPageProps<LoadingProps> {}

export class LoginPageLoginProps extends LoginPageProps<LoginProps> {}

export interface LoadingProps {
	title: string;
}

export interface LoginProps {
	title: string;
	subTitle: string;
	description: string;
	code: ValidInputProps | InvalidInputProps | SubmittableInputProps;
	errorMessage?: string;
}

export class SubmittableInputProps extends ValidInputProps {
	loginButton: ButtonProps;

	constructor(codeElements: Array<TextInputProps>, loginButton: ButtonProps) {
		super(codeElements);
		this.loginButton = loginButton;
	}
}
