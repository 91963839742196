import React from "react";
import { MasterPassword } from "../../settings/models/masterPassword";
import { ButtonProps } from "../../../models/props";
import { AskForMasterPasswordProps } from "../pages/askForMasterPassword";
import settingsActions from "../../settings/actions";

export default function useAskForMasterPasswordProps(): AskForMasterPasswordProps {
	const [masterPassword, setMasterPassword] = React.useState<string>("");
	const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

	function resolveSubmitButton(): ButtonProps | undefined {
		if (masterPassword.length === 0) return undefined;
		return {
			title: "Weiter",
			onSelect: () => {
				if (!MasterPassword.validatePublicKey(masterPassword)) {
					setErrorMessage("Invalid master password!");
				}
				settingsActions.loginWithMasterPassword(masterPassword);
			},
		};
	}
	return {
		title: "Enter Master Password",
		subTitle: "This is the master public key you got from the software provider.",
		input: {
			fieldId: "master-pw",
			fieldName: "master-pw",
			label: "application master pw",
			value: masterPassword,
			placeholder: "xxx-xxx-xxx",
			onChange: (value: string) => {
				if (/^[\w\s\d-.]*$/.test(value)) {
					setMasterPassword(value);
					setErrorMessage(undefined);
				}
			},
		},
		errorMessage: errorMessage,
		submitButton: resolveSubmitButton(),
	};
}
