import { AuthenticationState, AuthenticationStateType } from "../../../authentication/reducer/state";
import { Code } from "../../../authentication/models/code";
import { Pin } from "../../../authentication/models/pin";
import { FieldId } from "../models/domain";
import { TextInputProps } from "../../../models/props";
import { LoginPageLoadingProps, LoginPageLoginProps, SubmittableInputProps } from "../components/props";
import useAuthenticationState from "../../../authentication/selectors/useAuthenticationState";
import useExpectedPinCodeFromSettings from "./useExpectedPinCodeFromSettings";
import authenticationActions from "../../../authentication/actions";

export default function useLoginPageProps(): LoginPageLoadingProps | LoginPageLoginProps {
	const authenticationState = useAuthenticationState();
	const expectedPinCode = useExpectedPinCodeFromSettings();
	return toLoginPageProps(
		{
			expectedPinCodeLength: expectedPinCode.asPins().length,
			authenticationState: authenticationState,
		},
		{
			setPin: (index, pin: Pin) => authenticationActions.setPin(index, pin.toNumber()),
			login: () => authenticationActions.login(new Code(authenticationState.pins.join(""))),
		}
	);
}

interface LoginPagePropsActions {
	setPin: (pinIndex: number, pin: Pin) => void;
	login: () => void;
}

interface LoginPageState {
	expectedPinCodeLength: number;
	authenticationState: AuthenticationState;
}

function toLoginPageProps(
	state: LoginPageState,
	actions: LoginPagePropsActions
): LoginPageLoadingProps | LoginPageLoginProps {
	function resolvePin(value: string) {
		try {
			return new Pin(value);
		} catch (error) {
			return new Pin("0");
		}
	}

	function resolveValue(index: number) {
		try {
			return state.authenticationState.pins[index].toString();
		} catch (e) {
			return "0";
		}
	}

	function resolveErrorMessage() {
		if (state.authenticationState.type !== AuthenticationStateType.INVALID) return undefined;
		return "Pin Code Incorrekt";
	}

	if (state.authenticationState.type === AuthenticationStateType.CHECKING)
		return new LoginPageLoadingProps({
			title: "Loading...",
		});
	return new LoginPageLoginProps({
		title: "von",
		subTitle: "Luck",
		description: "welcome to the vonLuck internal orders app.",
		code: new SubmittableInputProps(
			[
				...Array(state.expectedPinCodeLength)
					.fill(null)
					.map((_, index): TextInputProps => {
						const fieldId = String(new FieldId(index + 1));
						return {
							label: fieldId,
							fieldId: fieldId,
							fieldName: fieldId,
							value: resolveValue(index),
							placeholder: "0",
							onChange: (pinLikeValue: string) => {
								actions.setPin(index, resolvePin(pinLikeValue));
							},
						};
					}),
			],
			{
				title: "Anmelden",
				onSelect: actions.login,
			}
		),
		errorMessage: resolveErrorMessage(),
	});
}
