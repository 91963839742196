import React from "react";
import { Code } from "../../../authentication/models/code";
import { AskForPinCodeContentProps } from "../pages/askForPinCode";
import { ButtonProps } from "../../../models/props";
import settingsActions from "../../settings/actions";
import authenticationActions from "../../../authentication/actions";

export default function useAskForPinCodeProps(): AskForPinCodeContentProps {
	const [pinCodeLike, setPinCode] = React.useState<string>("");

	function resolveSubmitButton(): ButtonProps | undefined {
		if (pinCodeLike.length < 4 || pinCodeLike.length % 2 > 0) return undefined;
		return {
			title: "Weiter",
			onSelect: () => {
				const pinCode = new Code(pinCodeLike);
				settingsActions.setPinCode(pinCode);
				authenticationActions.login(pinCode);
			},
		};
	}
	return {
		title: "Wähle einen Pin-Code",
		subTitle: "Der Pin-Code sollte aus 4 bis 8 Zahlen bestehen",
		codeInput: {
			fieldId: "pin-code",
			fieldName: "pin-code",
			label: "application pin code",
			value: pinCodeLike,
			placeholder: "0000",
			onChange: (value: string) => {
				if (/^\d*$/.test(value) && value.length < 9) {
					setPinCode(value);
				}
			},
		},
		submitButton: resolveSubmitButton(),
	};
}
