export class FieldId {
	private static readonly _preValue: string = "pin-";
	private readonly _value: string;

	constructor(value: number) {
		this._value = FieldId._preValue + value;
	}

	public static fromString(fieldIdLikeValue: string): FieldId {
		return new FieldId(Number(fieldIdLikeValue.slice(FieldId._preValue.length)));
	}

	public getNextFieldId(): FieldId {
		return new FieldId(this.toNumber() + 1);
	}

	public toString(): string {
		return this._value;
	}

	public toNumber(): number {
		return Number(this._value.slice(FieldId._preValue.length));
	}
}
