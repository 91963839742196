import { dispatchAppStateAction } from "../../../appState";
import { createOrderActions } from "./orderActions";
import orderRoutes from "../routes";
import createProductsActions from "./productsActions";

export const orderActions = createOrderActions({
	dispatchToAppState: dispatchAppStateAction,
	getAllOrders: orderRoutes.getAllOrdersForLocation,
	updateOrderState: orderRoutes.updateOrderState,
});

export const productActions = createProductsActions({
	dispatchToAppState: dispatchAppStateAction,
	getAllProductsForOrderId: orderRoutes.getAllProductsForOrder,
});
