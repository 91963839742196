import { OrderTable } from "./ninoxTableType";
import { createAxiosApi } from "../../../data/api";
import { AxiosResponseInterceptor } from "../../../data/interceptors";
import { createNinoxRoutes, NinoxRequestInterceptor } from "../../../ninox";
import { createOrdersRoutes } from "./routes";
import { getAppState } from "../../../appState";
import environment from "../../../misc/environment";

const ordersRoutes = createOrdersRoutes({
	getNinoxVonLuckLocationId: () => String(getAppState().settings.location?.id) || "0",
	ninoxRoutes: createNinoxRoutes<OrderTable>({
		ninoxTeamId: environment.REACT_APP_NINOX_TEAM_ID,
		ninoxDatabaseId: environment.REACT_APP_NINOX_PRODUCTS_DATABASE_ID,
		api: createAxiosApi({
			baseUrl: "https://api.ninoxdb.de/v1",
			requestInterceptors: [NinoxRequestInterceptor],
			responseInterceptors: [AxiosResponseInterceptor],
		}),
	}),
});

export default ordersRoutes;
