import { FunctionComponent } from "react";
import { IconProps, IconType, PaymentIconType, SocialMediaIconType } from "./models/domain";
import DefaultIcon from "./components/defaultIcon";
import iconOptions from "./iconOptions";
import socialMediaIconOptions from "./socialMediaIconOptions";
import paymentIconOptions from "./paymentIconOptions";

interface IconOption {
	type: any;
	icon: FunctionComponent<IconProps>;
}

function createIconResolver<IconType>(iconOptions: Array<IconOption>) {
	return function resolveIcon(type: IconType): FunctionComponent<IconProps> {
		return (
			iconOptions.find((option) => {
				return option.type === type;
			})?.icon || DefaultIcon
		);
	};
}

export const resolveIcon = createIconResolver<IconType>(iconOptions);

export const resolveSocialMediaIcon = createIconResolver<SocialMediaIconType>(socialMediaIconOptions);

export const resolvePaymentIcon = createIconResolver<PaymentIconType>(paymentIconOptions);
