import { GetAllOrdersForLocation, GetAllProductsForOrder, OrdersRoutes, UpdateOrderState } from "./routeModels";
import { NinoxRoutes } from "../../../ninox/models";
import {
	OrderResponse,
	OrderResponseNumberState,
	OrderResponseWithResolvedProducts,
	ProductResponse,
	ProductResponseWithAmount,
	ProductToOrderResponse,
} from "./responseModels";
import { OrderTable } from "./ninoxTableType";
import { toOrder, toOrderResponseState, toProductInOrder } from "./mapper";
import { OrderRequest } from "./requestModels";
import { Order } from "../models/domain";

interface OrdersRoutesDependencies {
	getNinoxVonLuckLocationId: () => string;
	ninoxRoutes: NinoxRoutes<OrderTable>;
}

export function createOrdersRoutes(dependencies: OrdersRoutesDependencies): OrdersRoutes {
	const getProductToOrder = (productToOrderId: number): Promise<ProductToOrderResponse> => {
		return dependencies.ninoxRoutes.getById<ProductToOrderResponse>(
			OrderTable.PRODUCT_TO_CUSTOMER_ORDER,
			productToOrderId
		);
	};

	const getProduct = (productId: number): Promise<ProductResponse> => {
		return dependencies.ninoxRoutes.getById<ProductResponse>(OrderTable.PRODUCTS, productId);
	};

	const resolveProductWithAmount = (productId: number, amount: number): Promise<ProductResponseWithAmount> => {
		return getProduct(productId).then((productResponse) => {
			return {
				...productResponse,
				amount: amount,
			};
		});
	};

	const resolveProductInOrderForProductToOrderId = (productToOrderId: number): Promise<ProductResponseWithAmount> => {
		return getProductToOrder(productToOrderId).then((productToOrderResponse) => {
			return resolveProductWithAmount(
				productToOrderResponse.fields.productId,
				productToOrderResponse.fields.amount
			);
		});
	};

	function resolveProductsInOrder(orderResponse: OrderResponse): Promise<OrderResponseWithResolvedProducts> {
		const hasProductToOrderIds = "product_to_order_ids" in orderResponse.fields;
		if (!hasProductToOrderIds || orderResponse.fields.product_to_order_ids.length === 0)
			return Promise.resolve({
				...orderResponse,
				products: [],
			});
		return Promise.all([
			...orderResponse.fields.product_to_order_ids.map((productToOrderId) => {
				return resolveProductInOrderForProductToOrderId(productToOrderId);
			}),
		]).then((productResponsesWithAmount: Array<ProductResponseWithAmount>) => {
			return {
				...orderResponse,
				products: [...productResponsesWithAmount],
			};
		});
	}

	const getAllOrdersForLocation: GetAllOrdersForLocation = (): Promise<Array<Order>> => {
		return Promise.all([
			dependencies.ninoxRoutes.getAllFilteredBy<OrderResponse>(OrderTable.CUSTOMER_ORDERS, {
				locationId: dependencies.getNinoxVonLuckLocationId(),
				state: OrderResponseNumberState.PENDING,
			}),
			dependencies.ninoxRoutes.getAllFilteredBy<OrderResponse>(OrderTable.CUSTOMER_ORDERS, {
				locationId: dependencies.getNinoxVonLuckLocationId(),
				state: OrderResponseNumberState.APPROVED,
			}),
			dependencies.ninoxRoutes.getAllFilteredBy<OrderResponse>(OrderTable.CUSTOMER_ORDERS, {
				locationId: dependencies.getNinoxVonLuckLocationId(),
				state: OrderResponseNumberState.PREPARED,
			}),
		])
			.then((response: Array<Array<OrderResponse>>): Array<OrderResponseWithResolvedProducts> => {
				return response.flat().map((orderResponse) => {
					return {
						...orderResponse,
						products: [],
					};
				});
			})
			.then((orderResponses: Array<OrderResponseWithResolvedProducts>): Array<Order> => {
				return orderResponses.map((orderResponse: OrderResponseWithResolvedProducts) => {
					return toOrder(orderResponse);
				});
			});
	};

	const getAllProductsForOrder: GetAllProductsForOrder = (orderId) => {
		return dependencies.ninoxRoutes
			.getAllFilteredBy<ProductToOrderResponse>(OrderTable.PRODUCT_TO_CUSTOMER_ORDER, { orderId })
			.then((productToOrderResponses) => {
				return productToOrderResponses.filter(
					(productToOrderResponse) =>
						!!productToOrderResponse.fields["productId"] && !!productToOrderResponse.fields["amount"]
				);
			})
			.then((productToOrderResponses) => {
				return Promise.all([
					...productToOrderResponses.map((productToOrderResponse) => {
						return resolveProductWithAmount(
							productToOrderResponse.fields.productId,
							productToOrderResponse.fields.amount
						);
					}),
				]);
			})
			.then((orderResponseWithResolvedProducts) => {
				return orderResponseWithResolvedProducts.map(toProductInOrder);
			});
	};

	const updateOrderState: UpdateOrderState = (orderId, orderState) => {
		const recordsForUpdate: Array<OrderRequest> = [
			{
				id: orderId,
				fields: {
					state: toOrderResponseState(orderState),
				},
			},
		];
		return dependencies.ninoxRoutes
			.updateOrCreateRecords<OrderRequest, OrderResponse>(OrderTable.CUSTOMER_ORDERS, recordsForUpdate)
			.then((response) => {
				return resolveProductsInOrder(response[0]);
			})
			.then(toOrder);
	};

	return {
		getAllOrdersForLocation: getAllOrdersForLocation,
		getAllProductsForOrder: getAllProductsForOrder,
		updateOrderState: updateOrderState,
	};
}
