import React, { FunctionComponent } from "react";

interface MenuProps {
	header: FunctionComponent<{ menuState: boolean; toggleMenuState: () => void }>;
	content: FunctionComponent<{ menuState: boolean; toggleMenuState: () => void }>;
}

export default function Menu(props: MenuProps) {
	const [menuState, setMenuState] = React.useState<boolean>(false);
	const toggleMenuState = () => setMenuState((prev) => !prev);
	return (
		<div className="relative text-left">
			<div className="z-0">{props.header({ menuState, toggleMenuState })}</div>
			{menuState && (
				<div
					role="menu"
					aria-orientation="vertical"
					aria-labelledby="menu-button"
					tabIndex={-1}
					className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md overflow-hidden shadow-lg bg-white dark:bg-gray-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 dark:divide-gray-800 focus:outline-none"
				>
					{props.content({ menuState, toggleMenuState })}
				</div>
			)}
		</div>
	);
}
