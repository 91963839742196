export enum OrderResponseNumberState {
	PENDING = 1,
	APPROVED = 3,
	PREPARED = 6,
	COLLECTED = 4,
	CANCELED = 5,
}

export enum OrderResponseStringState {
	PENDING = "PENDING",
	APPROVED = "APPROVED",
	PREPARED = "PREPARED",
	COLLECTED = "COLLECTED",
	CANCELED = "CANCELED",
}

export interface OrderResponse {
	id: number;
	fields: {
		locationId: number;
		orderPickUpId: string;
		state: OrderResponseNumberState;
		name: string;
		phone: string;
		email: string;
		date: string;
		time: string;
		notes: string;
		product_to_order_ids: Array<number>;
		price: number;
	};
}

export interface OrderResponseWithResolvedProducts extends OrderResponse {
	products: Array<ProductResponseWithAmount>;
}

export interface ProductResponse {
	id: number;
	fields: {
		name: string;
		price: number;
	};
}

export interface ProductResponseWithAmount extends ProductResponse {
	amount: number;
}

export interface ProductToOrderResponse {
	id: number;
	fields: {
		orderId: number;
		productId: number;
		amount: number;
	};
}
