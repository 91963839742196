import { LocationInState } from "../reducer/state";
import { LocationResponse } from "./responseModels";
import { GetAll } from "../../../ninox/models";
import { SettingsTable } from "./ninoxTableType";

export const ERROR_MESSAGE: string = "Fetching locations from the database failed.";

export interface LocationRouteDependencies {
	getAll: GetAll<SettingsTable>;
}

interface GetAllLocations {
	(): Promise<Array<LocationInState>>;
}

interface LocationRoutes {
	getAllLocations: GetAllLocations;
}

export default function createSettingsRoutes(request: LocationRouteDependencies): LocationRoutes {
	const getAllLocations: GetAllLocations = () => {
		return request
			.getAll<LocationResponse>(SettingsTable.LOCATIONS)
			.then((locationsFromNinox: Array<LocationResponse>) => {
				return locationsFromNinox
					.filter((locationFromNinox: LocationResponse): boolean => {
						return (
							"id" in locationFromNinox &&
							"fields" in locationFromNinox &&
							"name" in locationFromNinox.fields &&
							"state" in locationFromNinox.fields &&
							locationFromNinox.fields.state === "ONLINE"
						);
					})
					.map((locationFromNinox: LocationResponse): LocationInState => {
						return {
							id: locationFromNinox.id,
							name: locationFromNinox.fields.name.replace("vonLuck ", ""),
						};
					});
			})
			.catch(() => {
				throw ERROR_MESSAGE;
			});
	};

	return {
		getAllLocations,
	};
}
