import { Reducer } from "../../../models/reducer";
import { ProductsState } from "./state";
import { ProductsActionTypes } from "./actionTypes";
import { ProductsReducerActions } from "./reducerActions";

export function createInitialProductsState(): ProductsState {
	return {
		products: {},
	};
}

interface ProductsReducer<Action = any> extends Reducer<ProductsState, Action> {}

export const productsReducer: ProductsReducer = (state = createInitialProductsState(), action) => {
	switch (action.type) {
		case ProductsActionTypes.SET_PRODUCTS:
			return handleSetProducts(state, action);
		default:
			return state;
	}
};

const handleSetProducts: ProductsReducer<ProductsReducerActions.SetProducts> = (state, action) => {
	return {
		...state,
		products: {
			...state.products,
			[String(action.payload.orderId)]: [...action.payload.products],
		},
	};
};
