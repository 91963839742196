import { Reducer } from "../../../models/reducer";
import { SettingsState } from "./state";
import { SettingsActionTypes } from "./actionTypes";

function createSettingsState(): SettingsState {
	return {
		location: undefined,
		pinCode: undefined,
		printerIp: undefined,
	};
}

interface SettingsReducer<Action> extends Reducer<SettingsState, Action> {}

export const settingsReducer: SettingsReducer<any> = (state = createSettingsState(), action) => {
	switch (action.type) {
		case SettingsActionTypes.SET_LOCATION:
			return {
				...state,
				location: action.payload.location,
			};
		case SettingsActionTypes.SET_PIN_CODE:
			return {
				...state,
				pinCode: action.payload.pinCode,
			};
		case SettingsActionTypes.SET_PRINTER_IP:
			return {
				...state,
				printerIp: action.payload.printerIp,
			};
		case SettingsActionTypes.SET_MASTER_PASSWORD_RESULT:
			return {
				...state,
				masterPasswordResult: action.payload.masterPasswordResult,
			};
		default:
			return state;
	}
};
