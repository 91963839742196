import { DispatchAppStateAction } from "../../../appState";
import { ProductInOrder } from "../models/domain";
import { ProductsActions } from "./actionModels";
import { productsReducerActions } from "../reducer/reducerActions";

interface ProductActionDependencies {
	dispatchToAppState: DispatchAppStateAction;
	getAllProductsForOrderId: (orderId: number) => Promise<Array<ProductInOrder>>;
}

export default function createProductsActions(dependencies: ProductActionDependencies): ProductsActions.All {
	const fetchAllProductsForOrderId: ProductsActions.FetchAllProductsForOrderId = (orderId) => {
		return dependencies.getAllProductsForOrderId(orderId).then((products) => {
			dependencies.dispatchToAppState(productsReducerActions.setProducts(orderId, products));
		});
	};

	return {
		fetchAllProductsForOrderId: fetchAllProductsForOrderId,
	};
}
