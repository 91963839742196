import { ButtonProps, IconButtonProps } from "../../../models/props";
import { OrderState } from "../models/domain";

export class OrderListPageProps<Content> {
	content: Content;
	constructor(content: Content) {
		this.content = content;
	}
}

export class OrderListLoadingPageProps extends OrderListPageProps<{}> {}

export class OrderListWithOrdersPageProps extends OrderListPageProps<OrderListPageContentProps> {}

export interface OrderListPageContentProps {
	refreshButton: IconButtonProps;
	title: string;
	description: string;
	orderSections: Array<OrderSectionProps>;
	checkActionModal?: LoadingModalProps | ContentModalProps;
}

class ModalProps<Content> {
	content: Content;
	constructor(content: Content) {
		this.content = content;
	}
}

export class LoadingModalProps extends ModalProps<{ title: string }> {}

export class ContentModalProps extends ModalProps<{
	title: string;
	message: string;
	buttons: {
		cancel: ButtonProps;
		approve: ButtonProps;
	};
}> {}

export interface OrderSectionProps {
	title: string;
	description: string;
	orders: Array<OrderProps>;
}

export interface OrderProps {
	status: StatusProps;
	title: string;
	buttons: OrderButtonProps;
	details?: OrderDetailProps | OrderLoadingDetailProps;
}

export interface OrderButtonProps {
	toggleDetails: ButtonProps;
	approve?: ButtonProps;
	prepare?: ButtonProps;
	collect?: ButtonProps;
	cancel?: ButtonProps;
	print: IconButtonProps;
}

export interface StatusProps {
	type: OrderState;
	title: string;
}

export interface OrderDetailProps {
	meta: OrderMetaProps;
	products: ProductsProps;
	customer: CustomerProps;
}

export interface OrderLoadingDetailProps {
	title: "loading";
}

export function isOrderLoadingDetailProps(object: any): object is OrderLoadingDetailProps {
	return Object.keys(object).length === 1 && object.title === "loading";
}

export interface OrderMetaProps {
	status: string;
	title: string;
	date: string;
	time: string;
	notes: string;
}

export interface ProductsProps {
	title: string;
	products: Array<ProductProps>;
	price: {
		title: string;
		value: string;
	};
}

export interface ProductProps {
	title: string;
	amount: number;
}

export interface CustomerProps {
	title: string;
	name: string;
	phone?: string;
	mail?: string;
}
