import React from "react";
import { IconType } from "../../../components/icons";
import { LoadingPageProps } from "../../../commonPages/loading";
import { AskForLocationContentProps } from "../pages/askForLocation";
import settingsActions from "../../settings/actions";
import { LocationInState } from "../../settings/reducer/state";
import settingsRoutes from "../../settings/routes";

enum LocationMenuState {
	FETCHING = "FETCHING",
	COLLAPSED = "COLLAPSED",
	EXPANDED = "EXPANDED",
}

export default function useAskForLocationProps(): LoadingPageProps | AskForLocationContentProps {
	const locationsState = useLocationsState();
	if (locationsState.locationMenuState === LocationMenuState.FETCHING) return new LoadingPageProps();

	function resolveHeaderTitle() {
		if (locationsState.currentLocation === undefined) return "Locations";
		return locationsState.currentLocation.name;
	}

	function resolveSubmitButton() {
		if (locationsState.currentLocation === undefined) return undefined;
		return {
			title: "Weiter",
			onSelect: locationsState.submitLocation,
		};
	}
	return {
		title: "Wähle deine vonLuck Location",
		selectLocation: {
			header: {
				title: resolveHeaderTitle(),
				expansionButton: {
					title: "Ausklappen",
					icon: IconType.CHEVRON_LEFT_STROKE,
					onSelect: locationsState.toggleLocationMenu,
				},
			},
			content: {
				locations: [
					[
						...locationsState.locations.map((location) => {
							return {
								title: location.name,
								onSelect: () => locationsState.setCurrentLocation(location),
							};
						}),
					],
				],
			},
		},
		submitButton: resolveSubmitButton(),
	};
}

interface LocationsState {
	locationMenuState: LocationMenuState;
	locations: Array<LocationInState>;
	currentLocation?: LocationInState;
	toggleLocationMenu: () => void;
	setCurrentLocation: (location: LocationInState) => void;
	submitLocation: () => void;
}

function useLocationsState(): LocationsState {
	const [locationMenuState, setLocationMenuState] = React.useState<LocationMenuState>(LocationMenuState.FETCHING);
	const [locations, setLocations] = React.useState<Array<LocationInState>>([]);
	const [currentLocation, setCurrentLocation] = React.useState<LocationInState | undefined>(undefined);

	function getLocations(): Promise<void> {
		setLocationMenuState(LocationMenuState.FETCHING);
		return settingsRoutes
			.getAllLocations()
			.then((locations) => setLocations(locations))
			.finally(() => {
				setLocationMenuState(LocationMenuState.COLLAPSED);
			});
	}

	function toggleLocationMenu() {
		switch (locationMenuState) {
			case LocationMenuState.COLLAPSED:
				return setLocationMenuState(LocationMenuState.EXPANDED);
			case LocationMenuState.EXPANDED:
				return setLocationMenuState(LocationMenuState.COLLAPSED);
			default:
				return;
		}
	}

	function submitLocation(): void {
		!!currentLocation && settingsActions.setLocation(currentLocation);
	}

	React.useEffect(() => {
		getLocations();
		// eslint-disable-next-line
	}, []);

	return {
		locationMenuState,
		locations,
		currentLocation,
		toggleLocationMenu,
		setCurrentLocation: (location: LocationInState) => {
			setCurrentLocation(location);
			setLocationMenuState(LocationMenuState.COLLAPSED);
		},
		submitLocation,
	};
}
