export class IPv4 {
	private readonly address: string;

	constructor(ipv4Like: string) {
		if (IPv4.isIPv4(ipv4Like)) throw new IPv4.InValidIPv4Error(ipv4Like);
		this.address = ipv4Like;
	}

	public static InValidIPv4Error = class extends Error {
		constructor(ipv4Like: string) {
			super(`given value: "${ipv4Like}" is not a valid IPv4. IPv4 ranges from 0.0.0.0 - 255.255.255.255.`);
		}
	};

	public static isIPv4(ipv4Like: string): boolean {
		return /\b((([0-2]\d[0-5])|(\d{2})|(\d))\.){3}(([0-2]\d[0-5])|(\d{2})|(\d))\b/.test(ipv4Like);
	}

	public static isIPv4Element(ipv4LikeElement: string): boolean {
		return /([0-2]\d[0-5])|(\d{2})|(\d)/.test(ipv4LikeElement);
	}

	toString() {
		return this.address;
	}
}
