import { Order, OrderState } from "../models/domain";
import { DispatchAppStateAction } from "../../../appState";
import { ordersReducerActions } from "../reducer/reducerActions";
import { OrdersActions } from "./actionModels";

interface OrderActionDependencies {
	dispatchToAppState: DispatchAppStateAction;
	getAllOrders: () => Promise<Array<Order>>;
	updateOrderState: (orderId: number, orderState: OrderState) => Promise<Order>;
}

export function createOrderActions(dependencies: OrderActionDependencies): OrdersActions.All {
	const fetchAllOrders: OrdersActions.FetchAllOrders = () => {
		dependencies.dispatchToAppState(ordersReducerActions.setOrders([]));
		return dependencies.getAllOrders().then((allOrders) => {
			dependencies.dispatchToAppState(ordersReducerActions.setOrders(allOrders));
		});
	};

	const setOrderState: OrdersActions.SetOrderState = (orderId, orderState) => {
		dependencies.dispatchToAppState(ordersReducerActions.setOrderState(orderId, OrderState.LOADING));
		return dependencies.updateOrderState(orderId, orderState).then((updatedOrder) => {
			dependencies.dispatchToAppState(ordersReducerActions.setOrder(updatedOrder));
		});
	};

	return {
		fetchAllOrders,
		setOrderState,
	};
}
