import { PaymentIconOption, PaymentIconType } from "./models/domain";
import Visa from "./components/visa";
import Amex from "./components/amex";
import Mastercard from "./components/mastercard";
import Maestro from "./components/maestro";
import JCB from "./components/jcb";
import PayPal from "./components/paypal";
import ApplePay from "./components/applePay";
import GooglePay from "./components/googlePay";

const paymentIconOptions: Array<PaymentIconOption> = [
	{
		type: PaymentIconType.VISA,
		icon: Visa,
	},
	{
		type: PaymentIconType.AMEX,
		icon: Amex,
	},
	{
		type: PaymentIconType.MASTERCARD,
		icon: Mastercard,
	},
	{
		type: PaymentIconType.MAESTRO,
		icon: Maestro,
	},
	{
		type: PaymentIconType.JCB,
		icon: JCB,
	},
	{
		type: PaymentIconType.PAYPAL,
		icon: PayPal,
	},
	{
		type: PaymentIconType.APPLE_PAY,
		icon: ApplePay,
	},
	{
		type: PaymentIconType.GOOGLE_PAY,
		icon: GooglePay,
	},
];

export default paymentIconOptions;
