import React, { PropsWithChildren } from "react";
import { useRequiredSettings } from "./selectors/useRequiredSettings";
import AskForLocationPage from "./pages/askForLocation";
import AskForPinCodePage from "./pages/askForPinCode";
import { dispatchAppStateAction } from "../../appState";
import AskForMasterPassword from "./pages/askForMasterPassword";

export default function ResolveRequiredSettings(props: PropsWithChildren<{}>) {
	const requiredSettingsState = useRequiredSettings();

	React.useEffect(() => {
		dispatchAppStateAction({ type: "EMPTY_ACTION_TO_TRIGGER_THE_APP_STATE_LISTENERS", payload: {} });
		// eslint-disable-next-line
	}, []);

	if (requiredSettingsState.hasNoMasterPassword) return <AskForMasterPassword />;
	if (requiredSettingsState.hasNoLocation) return <AskForLocationPage />;
	if (requiredSettingsState.hasNoPinCode) return <AskForPinCodePage />;
	return <>{props.children}</>;
}
