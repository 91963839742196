import { FunctionComponent } from "react";

export enum IconType {
	DEFAULT,
	SUN,
	MOON,
	MOON_SUN,
	SETTINGS,
	LOCATION,
	SHOPPING_BAG,
	HOME,
	PHONE,
	MAIL,
	OPEN,
	CLOSED,
	BASKET,
	ADD,
	REMOVE,
	CLOSE,
	CHECK_MARK,
	QUESTION_MARK,
	COOKIES,
	RIGHT,
	MENU,
	PRINT,
	REFRESH,
	LOGOUT,
	INFO,
	ERROR,
	WARNING,
	OK,
	ONLINE,
	OFFLINE,
	CHEVRON_DOWN_STROKE,
	CHEVRON_DOWN_FILL,
	CHEVRON_LEFT_STROKE,
	CHEVRON_LEFT_FILL,
	LOCK_OPEN_STROKE,
	LOCK_CLOSED_STROKE,
	DOTS_VERTICAL,
}

export enum SocialMediaIconType {
	TOOOGOODTOGO,
	TRIPADVISOR,
	YELP,
}

export enum PaymentIconType {
	VISA,
	APPLE_PAY,
	GOOGLE_PAY,
	PAYPAL,
	MASTERCARD,
	MAESTRO,
	AMEX,
	JCB,
}

export interface PaymentIconOption {
	type: PaymentIconType;
	icon: FunctionComponent<IconProps>;
}

export interface SocialIconOption {
	type: SocialMediaIconType;
	icon: FunctionComponent<IconProps>;
}

export interface IconOption {
	type: IconType;
	icon: FunctionComponent<IconProps>;
}

export interface IconProps {
	className?: string;
}
