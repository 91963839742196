export class Pin {
	private readonly _value: number;

	constructor(pinLikeValue: string) {
		if (!/^[0-9]$/.test(pinLikeValue)) throw new Error('Pin: "' + pinLikeValue + '" is not an int [0-9]');
		this._value = Number(pinLikeValue);
	}

	toNumber() {
		return this._value;
	}

	toString() {
		return String(this._value);
	}

	static fromNumber(pinLikeNumber: number): Pin {
		return new Pin(String(pinLikeNumber));
	}
}
