export interface AuthenticationState {
	type: AuthenticationStateType;
	pins: Array<number>;
}

export enum AuthenticationStateType {
	IDLE = "IDLE",
	CHECKING = "CHECKING",
	VALID = "VALID",
	INVALID = "INVALID",
}
