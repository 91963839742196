import React from "react";
import { TextInputProps } from "../models/props";
import { FieldId } from "../features/login/models/domain";
import { focusElement } from "../utils/view";

export interface PinInputProps extends TextInputProps {
	pinCodeLength: number;
}

export function PinInput(props: PinInputProps) {
	return (
		<div className="self-stretch col-span-2">
			<label htmlFor={props.fieldId} className="sr-only">
				{props.label}
			</label>
			<input
				className="w-full bg-white select-all dark:bg-gray-800 py-4 px-1 rounded text-mono text-lg text-center text-gray-900 dark:text-gray-100 focus:outline-none focus:ring focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-green-400 dark:focus:ring-green-600"
				pattern="[0-9]*"
				id={props.fieldId}
				name={props.fieldName}
				value={props.value}
				placeholder={props.placeholder}
				onKeyDown={(event) => {
					const value = event.key;

					if (/^\d$/.test(value)) {
						props.onChange(value);
						const nextFieldId = FieldId.fromString(props.fieldId).getNextFieldId();

						if (nextFieldId.toNumber() > props.pinCodeLength) {
							focusElement("login-button");
						} else {
							focusElement(nextFieldId.toString());
						}
					}
				}}
				onChange={() => {
					/*to silence the react error*/
				}}
			/>
		</div>
	);
}
