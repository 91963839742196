import { Code } from "../authentication/models/code";
import { IPv4 } from "../features/settings/models/ipv4";
import { MasterPassword } from "../features/settings/models/masterPassword";
import { LocationInState } from "../features/settings/reducer/state";
import { createLocalStorageActions, BrowserStorageActionsModels } from "@aricma/browser-storage-actions";
import { dispatchAppStateAction, getAppState } from "./index";
import * as settingsReducerActions from "../features/settings/reducer/reducerActions";
import environmentVariables from "../misc/environment";

const LOCATION_NAME_SPACE_IN_STORAGE = "location";
const PIN_CODE_NAME_SPACE_IN_STORAGE = "pinCode";
const PRINTER_IP_NAME_SPACE_IN_STORAGE = "printerIp";
const MASTER_PW_NAME_SPACE_IN_STORAGE = "masterPasswordResult";

interface AppStoreSubscriptions {
	getLocationFromStorageIfStateIsEmpty: () => void;
	getPinCodeFromStorageIfStateIsEmpty: () => void;
	setLocationInStorageFromState: () => void;
	setPinCodeInStorageFromState: () => void;
	getPrinterIpFromStorageIfStateIsEmpty: () => void;
	setPrinterIpInStorageFromState: () => void;
	getMasterPasswordResultFromStorageIfStateIsEmpty: () => void;
	setMasterPasswordResultInStorageFromState: () => void;
}

interface AppStoreSubscriptionDependencies {
	storageActions: BrowserStorageActionsModels.BrowserStorageActions;
	stateActions: {
		setLocation: (location: LocationInState) => void;
		setPinCode: (pinCode: Code) => void;
		setPrinterIp: (printerIp: IPv4) => void;
		setMasterPasswordResult: (masterPasswordResult: string) => void;
	};
}

export function createAppStoreSubscriptions(dependencies: AppStoreSubscriptionDependencies): AppStoreSubscriptions {
	const getLocationFromStorageIfStateIsEmpty = () => {
		const locationFromState = getAppState().settings.location;
		if (locationFromState !== undefined) return;

		try {
			const locationFromStorage =
				dependencies.storageActions.getItemByName<LocationInState>(LOCATION_NAME_SPACE_IN_STORAGE).value;
			dependencies.stateActions.setLocation(locationFromStorage);
		} catch (e) {
			return;
		}
	};

	const setLocationInStorageFromState = () => {
		const locationFromState = getAppState().settings.location;
		if (locationFromState === undefined) return;
		try {
			dependencies.storageActions.removeItemByName(LOCATION_NAME_SPACE_IN_STORAGE);
		} catch (e) {}
		try {
			dependencies.storageActions.addItem({
				name: LOCATION_NAME_SPACE_IN_STORAGE,
				value: locationFromState,
			});
		} catch (e) {
			return;
		}
	};

	const getPinCodeFromStorageIfStateIsEmpty = () => {
		const pinCodeFromState = getAppState().settings.pinCode;
		if (pinCodeFromState !== undefined) return;

		try {
			const pinCodeFromStorage =
				dependencies.storageActions.getItemByName<Code>(PIN_CODE_NAME_SPACE_IN_STORAGE).value;
			dependencies.stateActions.setPinCode(pinCodeFromStorage);
		} catch (e) {
			return;
		}
	};

	const setPinCodeInStorageFromState = () => {
		const pinCodeFromState = getAppState().settings.pinCode;
		if (pinCodeFromState === undefined) return;
		try {
			dependencies.storageActions.removeItemByName(PIN_CODE_NAME_SPACE_IN_STORAGE);
		} catch (e) {}
		try {
			dependencies.storageActions.addItem({
				name: PIN_CODE_NAME_SPACE_IN_STORAGE,
				value: pinCodeFromState,
			});
		} catch (e) {
			return;
		}
	};

	const getPrinterIpFromStorageIfStateIsEmpty = () => {
		const printerIpFromState = getAppState().settings.printerIp;
		if (printerIpFromState !== undefined) return;

		try {
			const printerIpFromStorage = dependencies.storageActions.getItemByName<string>(
				PRINTER_IP_NAME_SPACE_IN_STORAGE
			).value;
			dependencies.stateActions.setPrinterIp(new IPv4(printerIpFromStorage));
		} catch (e) {
			return;
		}
	};

	const setPrinterIpInStorageFromState = () => {
		const printerIpFromState = getAppState().settings.printerIp;
		if (printerIpFromState === undefined) return;
		try {
			dependencies.storageActions.removeItemByName(PRINTER_IP_NAME_SPACE_IN_STORAGE);
		} catch (e) {}
		try {
			dependencies.storageActions.addItem({
				name: PRINTER_IP_NAME_SPACE_IN_STORAGE,
				value: printerIpFromState,
			});
		} catch (e) {
			return;
		}
	};

	const getMasterPasswordResultFromStorageIfStateIsEmpty = () => {
		const masterPasswordResultFromState = getAppState().settings.masterPasswordResult;
		if (masterPasswordResultFromState !== undefined) return;

		try {
			const masterPasswordResultFromStorage = dependencies.storageActions.getItemByName<string>(
				MASTER_PW_NAME_SPACE_IN_STORAGE
			).value;

			if (MasterPassword.validateMasterPasswordResult(masterPasswordResultFromStorage)) {
				dependencies.stateActions.setMasterPasswordResult(masterPasswordResultFromStorage);
			} else {
				return;
			}
		} catch (e) {
			return;
		}
	};

	const setMasterPasswordResultInStorageFromState = () => {
		const masterPasswordResultFromState = getAppState().settings.masterPasswordResult;
		if (masterPasswordResultFromState === undefined) return;
		try {
			dependencies.storageActions.removeItemByName(MASTER_PW_NAME_SPACE_IN_STORAGE);
		} catch (e) {}
		try {
			dependencies.storageActions.addItem({
				name: MASTER_PW_NAME_SPACE_IN_STORAGE,
				value: masterPasswordResultFromState,
			});
		} catch (e) {
			console.log(e);
			return;
		}
	};

	return {
		getLocationFromStorageIfStateIsEmpty: getLocationFromStorageIfStateIsEmpty,
		getPinCodeFromStorageIfStateIsEmpty: getPinCodeFromStorageIfStateIsEmpty,
		setLocationInStorageFromState: setLocationInStorageFromState,
		setPinCodeInStorageFromState: setPinCodeInStorageFromState,
		getPrinterIpFromStorageIfStateIsEmpty: getPrinterIpFromStorageIfStateIsEmpty,
		setPrinterIpInStorageFromState: setPrinterIpInStorageFromState,
		getMasterPasswordResultFromStorageIfStateIsEmpty: getMasterPasswordResultFromStorageIfStateIsEmpty,
		setMasterPasswordResultInStorageFromState: setMasterPasswordResultInStorageFromState,
	};
}

export default createAppStoreSubscriptions({
	storageActions: createLocalStorageActions({
		nameSpace: environmentVariables.REACT_APP_LOCAL_STORAGE_NAME_SPACE,
		localStorage: localStorage,
	}),
	stateActions: {
		setLocation: (location: LocationInState) =>
			dispatchAppStateAction(settingsReducerActions.setLocation(location)),
		setPinCode: (pinCode: Code) => dispatchAppStateAction(settingsReducerActions.setPinCode(pinCode)),
		setPrinterIp: (printerIp: IPv4) => dispatchAppStateAction(settingsReducerActions.setPrinterIp(printerIp)),
		setMasterPasswordResult: (masterPasswordResult: string) =>
			dispatchAppStateAction(settingsReducerActions.setMasterPasswordResult(masterPasswordResult)),
	},
});
