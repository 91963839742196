import { IconOption, IconType } from "./models/domain";
import Location from "./components/location";
import Phone from "./components/phone";
import Mail from "./components/mail";
import Open from "./components/open";
import Closed from "./components/closed";
import Basket from "./components/basket";
import Add from "./components/add";
import Remove from "./components/remove";
import Close from "./components/close";
import CheckMark from "./components/checkmark";
import DefaultIcon from "./components/defaultIcon";
import QuestionMark from "./components/questionmark";
import Cookies from "./components/cookies";
import Right from "./components/right";
import Menu from "./components/menu";
import Print from "./components/print";
import Refresh from "./components/refresh";
import Logout from "./components/logout";
import Info from "./components/info";
import Ok from "./components/ok";
import Error from "./components/error";
import Warning from "./components/warning";
import Online from "./components/online";
import Offline from "./components/offline";
import { ChevronDownFill, ChevronDownStroke } from "./components/chevronDown";
import { ChevronLeftFill, ChevronLeftStroke } from "./components/chevronLeft";
import Settings from "./components/settings";
import LockClosedStroke from "./components/lockClosed";
import LockOpenStroke from "./components/lockOpen";
import DotsVerticalStroke from "./components/dotsVertical";

const iconOptions: Array<IconOption> = [
	{
		type: IconType.DEFAULT,
		icon: DefaultIcon,
	},
	{
		type: IconType.LOCATION,
		icon: Location,
	},
	{
		type: IconType.PHONE,
		icon: Phone,
	},
	{
		type: IconType.MAIL,
		icon: Mail,
	},
	{
		type: IconType.OPEN,
		icon: Open,
	},
	{
		type: IconType.CLOSED,
		icon: Closed,
	},
	{
		type: IconType.BASKET,
		icon: Basket,
	},
	{
		type: IconType.ADD,
		icon: Add,
	},
	{
		type: IconType.REMOVE,
		icon: Remove,
	},
	{
		type: IconType.CLOSE,
		icon: Close,
	},
	{
		type: IconType.CHECK_MARK,
		icon: CheckMark,
	},
	{
		type: IconType.QUESTION_MARK,
		icon: QuestionMark,
	},
	{
		type: IconType.COOKIES,
		icon: Cookies,
	},
	{
		type: IconType.RIGHT,
		icon: Right,
	},
	{
		type: IconType.MENU,
		icon: Menu,
	},
	{
		type: IconType.PRINT,
		icon: Print,
	},
	{
		type: IconType.REFRESH,
		icon: Refresh,
	},
	{
		type: IconType.LOGOUT,
		icon: Logout,
	},
	{
		type: IconType.INFO,
		icon: Info,
	},
	{
		type: IconType.OK,
		icon: Ok,
	},
	{
		type: IconType.ERROR,
		icon: Error,
	},
	{
		type: IconType.WARNING,
		icon: Warning,
	},
	{
		type: IconType.ONLINE,
		icon: Online,
	},
	{
		type: IconType.OFFLINE,
		icon: Offline,
	},
	{
		type: IconType.CHEVRON_DOWN_STROKE,
		icon: ChevronDownStroke,
	},
	{
		type: IconType.CHEVRON_DOWN_FILL,
		icon: ChevronDownFill,
	},
	{
		type: IconType.CHEVRON_LEFT_STROKE,
		icon: ChevronLeftStroke,
	},
	{
		type: IconType.CHEVRON_LEFT_FILL,
		icon: ChevronLeftFill,
	},
	{
		type: IconType.SETTINGS,
		icon: Settings,
	},
	{
		type: IconType.LOCK_OPEN_STROKE,
		icon: LockOpenStroke,
	},
	{
		type: IconType.LOCK_CLOSED_STROKE,
		icon: LockClosedStroke,
	},
	{
		type: IconType.DOTS_VERTICAL,
		icon: DotsVerticalStroke,
	},
];

export default iconOptions;
