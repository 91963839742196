import { Code } from "../models/code";
import { AuthenticationActions } from "./actionTypes";
import { AuthenticationStateType } from "../reducer/state";
import * as actionTypes from "./actionTypes";
import * as reducerActions from "../reducer/reducerActions";

interface AuthenticationActionDependencies {
	getExpectedPinCode: () => Code;
	dispatchToAppState: (action: any) => void;
}

export function createAuthenticationActions(dependencies: AuthenticationActionDependencies): AuthenticationActions {
	const setPin: actionTypes.SetPin = (pinIndex, pin) => {
		dependencies.dispatchToAppState(reducerActions.setPin(pinIndex, pin));
	};

	const setPinCode: actionTypes.SetPinCode = (pinCode) => {
		dependencies.dispatchToAppState(reducerActions.setPinCode(pinCode));
	};

	const login: actionTypes.Login = async (code) => {
		dependencies.dispatchToAppState(reducerActions.setAuthenticationType(AuthenticationStateType.CHECKING));

		if (code.sameAs(dependencies.getExpectedPinCode())) {
			dependencies.dispatchToAppState(reducerActions.setAuthenticationType(AuthenticationStateType.VALID));
		} else {
			dependencies.dispatchToAppState(reducerActions.setAuthenticationType(AuthenticationStateType.INVALID));
		}
	};

	const logout: actionTypes.Logout = async () => {
		dependencies.dispatchToAppState(reducerActions.setAuthenticationType(AuthenticationStateType.CHECKING));
		dependencies.dispatchToAppState(reducerActions.setPinCode(dependencies.getExpectedPinCode().nullify()));
		dependencies.dispatchToAppState(reducerActions.setAuthenticationType(AuthenticationStateType.IDLE));
	};

	return {
		setPin: setPin,
		setPinCode: setPinCode,
		login: login,
		logout: logout,
	};
}
