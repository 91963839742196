import React from "react";
import { IconProps } from "../models/domain";

export default function PayPal(props: IconProps) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 150 504 200">
			<g>
				<path
					fill="#139AD6"
					d="M389.6,221.2h-27.2c-1.6,0-3.2,1.6-4,3.2l-11.2,70.4c0,1.6,0.8,2.4,2.4,2.4H364 c1.6,0,2.4-0.8,2.4-2.4l3.2-20c0-1.6,1.6-3.2,4-3.2h8.8c18.4,0,28.8-8.8,31.2-26.4c1.6-7.2,0-13.6-3.2-17.6 C405.6,223.6,398.4,221.2,389.6,221.2 M392.8,247.6c-1.6,9.6-8.8,9.6-16,9.6H372l3.2-18.4c0-0.8,0.8-1.6,2.4-1.6h1.6 c4.8,0,9.6,0,12,3.2C392.8,241.2,392.8,243.6,392.8,247.6"
				/>
				<path
					fill="#263B80"
					d="M193.6,221.2h-27.2c-1.6,0-3.2,1.6-4,3.2l-11.2,70.4c0,1.6,0.8,2.4,2.4,2.4h12.8 c1.6,0,3.2-1.6,4-3.2l3.2-19.2c0-1.6,1.6-3.2,4-3.2h8.8c18.4,0,28.8-8.8,31.2-26.4c1.6-7.2,0-13.6-3.2-17.6 C209.6,223.6,203.2,221.2,193.6,221.2 M196.8,247.6c-1.6,9.6-8.8,9.6-16,9.6h-4l3.2-18.4c0-0.8,0.8-1.6,2.4-1.6h1.6 c4.8,0,9.6,0,12,3.2C196.8,241.2,197.6,243.6,196.8,247.6"
				/>
				<path
					fill="#263B80"
					d="M276,246.8h-12.8c-0.8,0-2.4,0.8-2.4,1.6l-0.8,4l-0.8-1.6c-3.2-4-8.8-5.6-15.2-5.6 c-14.4,0-27.2,11.2-29.6,26.4c-1.6,8,0.8,15.2,4.8,20s9.6,6.4,16.8,6.4c12,0,18.4-7.2,18.4-7.2l-0.8,4c0,1.6,0.8,2.4,2.4,2.4h12 c1.6,0,3.2-1.6,4-3.2l7.2-44.8C278.4,248.4,276.8,246.8,276,246.8 M257.6,272.4c-1.6,7.2-7.2,12.8-15.2,12.8c-4,0-7.2-1.6-8.8-3.2 c-1.6-2.4-2.4-5.6-2.4-9.6c0.8-7.2,7.2-12.8,14.4-12.8c4,0,6.4,1.6,8.8,3.2C256.8,265.2,257.6,269.2,257.6,272.4"
				/>
				<path
					fill="#139AD6"
					d="M471.2,246.8h-12.8c-0.8,0-2.4,0.8-2.4,1.6l-0.8,4l-0.8-1.6c-3.2-4-8.8-5.6-15.2-5.6 c-14.4,0-27.2,11.2-29.6,26.4c-1.6,8,0.8,15.2,4.8,20s9.6,6.4,16.8,6.4c12,0,18.4-7.2,18.4-7.2l-0.8,4c0,1.6,0.8,2.4,2.4,2.4h12 c1.6,0,3.2-1.6,4-3.2l7.2-44.8C473.6,248.4,472.8,246.8,471.2,246.8 M452.8,272.4c-1.6,7.2-7.2,12.8-15.2,12.8c-4,0-7.2-1.6-8.8-3.2 c-1.6-2.4-2.4-5.6-2.4-9.6c0.8-7.2,7.2-12.8,14.4-12.8c4,0,6.4,1.6,8.8,3.2C452.8,265.2,453.6,269.2,452.8,272.4"
				/>
				<path
					fill="#263B80"
					d="M345.6,246.8H332c-1.6,0-2.4,0.8-3.2,1.6l-17.6,27.2l-8-25.6c-0.8-1.6-1.6-2.4-4-2.4h-12.8 c-1.6,0-2.4,1.6-2.4,3.2l14.4,42.4l-13.6,19.2c-0.8,1.6,0,4,1.6,4h12.8c1.6,0,2.4-0.8,3.2-1.6l44-63.2 C348.8,249.2,347.2,246.8,345.6,246.8"
				/>
				<path
					fill="#139AD6"
					d="M486.4,223.6l-11.2,72c0,1.6,0.8,2.4,2.4,2.4h11.2c1.6,0,3.2-1.6,4-3.2l11.2-70.4 c0-1.6-0.8-2.4-2.4-2.4h-12.8C488,221.2,487.2,222,486.4,223.6"
				/>
				<path
					fill="#263B80"
					d="M92,197.2c-5.6-6.4-16-9.6-30.4-9.6h-40c-2.4,0-4.8,2.4-5.6,4.8L0,297.2c0,2.4,1.6,4,3.2,4H28 l6.4-39.2v1.6c0.8-2.4,3.2-4.8,5.6-4.8h12c23.2,0,40.8-9.6,46.4-36c0-0.8,0-1.6,0-2.4c-0.8,0-0.8,0,0,0 C99.2,210,97.6,203.6,92,197.2"
				/>
				<path
					fill="#139AD6"
					d="M97.6,220.4L97.6,220.4c0,0.8,0,1.6,0,2.4c-5.6,27.2-23.2,36-46.4,36h-12c-2.4,0-4.8,2.4-5.6,4.8 l-8,48.8c0,1.6,0.8,3.2,3.2,3.2h20.8c2.4,0,4.8-1.6,4.8-4v-0.8l4-24.8v-1.6c0-2.4,2.4-4,4.8-4h3.2c20,0,36-8,40-32 c1.6-9.6,0.8-18.4-4-24C101.6,222.8,100,221.2,97.6,220.4"
				/>
				<path
					fill="#232C65"
					d="M92,218c-0.8,0-1.6-0.8-2.4-0.8s-1.6,0-2.4-0.8c-3.2-0.8-6.4-0.8-10.4-0.8H45.6c-0.8,0-1.6,0-2.4,0.8 c-1.6,0.8-2.4,2.4-2.4,4L34.4,262v1.6c0.8-2.4,3.2-4.8,5.6-4.8h12c23.2,0,40.8-9.6,46.4-36c0-0.8,0-1.6,0.8-2.4 c-1.6-0.8-2.4-1.6-4-1.6C92.8,218,92.8,218,92,218"
				/>
			</g>
		</svg>
	);
}
