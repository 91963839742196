import { AuthenticationState, AuthenticationStateType } from "./state";
import { AuthenticationActionTypes } from "./actionTypes";
import { Reducer } from "../../models/reducer";
import * as reducerActions from "./reducerActions";

export function createAuthenticationState(): AuthenticationState {
	return {
		type: AuthenticationStateType.IDLE,
		pins: [],
	};
}

interface AuthenticationReducer<Action = any> extends Reducer<AuthenticationState, Action> {}

export const authenticationReducer: AuthenticationReducer = (state = createAuthenticationState(), action) => {
	switch (action.type) {
		case AuthenticationActionTypes.SET_AUTHENTICATION_TYPE:
			return handleSetAuthenticationType(state, action);
		case AuthenticationActionTypes.SET_PIN:
			return handleSetPin(state, action);
		case AuthenticationActionTypes.SET_PIN_CODE:
			return handleSetPinCode(state, action);
		default:
			return state;
	}
};

const handleSetAuthenticationType: AuthenticationReducer<reducerActions.SetAuthenticationType> = (state, action) => {
	return {
		...state,
		type: action.payload.type,
	};
};

const handleSetPin: AuthenticationReducer<reducerActions.SetPin> = (state, action) => {
	if (state.pins[action.payload.pinIndex]) {
		return {
			...state,
			pins: [
				...state.pins.map((pin, index) => {
					if (index === action.payload.pinIndex) {
						return action.payload.value;
					} else {
						return pin;
					}
				}),
			],
		};
	} else {
		return {
			...state,
			pins: [
				...Array(action.payload.pinIndex + 1)
					.fill(0)
					.map((emptyPin, index) => {
						if (state.pins[index]) {
							return state.pins[index];
						} else {
							return emptyPin;
						}
					})
					.map((pin, index) => {
						if (index === action.payload.pinIndex) {
							return action.payload.value;
						} else {
							return pin;
						}
					}),
			],
		};
	}
};

const handleSetPinCode: AuthenticationReducer<reducerActions.SetPinCode> = (state, action) => {
	return {
		...state,
		pins: [...action.payload.pinCode.asPins().map((pin) => pin.toNumber())],
	};
};
