import createPrinterRoutes from "./printerRoutes";
import { createAxiosApi } from "../../../data/api";
import { AxiosResponseInterceptor } from "../../../data/interceptors";
import { PrinterRequestInterceptor } from "./printerInterceptors";
import { getAppState } from "../../../appState";
import environment from "../../../misc/environment";

const printerRoutes = createPrinterRoutes({
	getApi: () =>
		createAxiosApi({
			baseUrl: `${resolveHTTPForEnvironment()}://${
				getAppState().settings.printerIp || "0.0.0.0"
			}/cgi-bin/epos/service.cgi`,
			timeout: 5000,
			requestInterceptors: [PrinterRequestInterceptor],
			responseInterceptors: [AxiosResponseInterceptor],
		}),
});

export default printerRoutes;

function resolveHTTPForEnvironment() {
	return environment.NODE_ENV === "production" ? "https" : "http";
}
