import { IconType } from "../components/icons";

export interface LinkProps {
	title: string;
	link: string;
}

export interface IconLinkProps extends LinkProps {
	icon: IconType;
}

export enum ButtonPropsType {
	PRIME,
	SEC,
	DANGER,
}

export interface ButtonProps {
	type?: ButtonPropsType;
	title: string;
	onSelect: () => void;
}

export interface IconButtonProps extends ButtonProps {
	icon: IconType;
}

export interface TextInputProps {
	label: string;
	fieldId: string;
	fieldName: string;
	value: string;
	placeholder: string;
	onChange: (value: string) => void;
}
