import React from "react";
import Croissant from "./croissant";

export default function Loading() {
	return (
		<div className="w-full h-full p-10 flex flex-col items-center justify-center space-y-3">
			<Croissant className="w-36 text-gray-400 dark:text-gray-600" />
			<p className="font-hand text-lg text-gray-600 dark:text-gray-400">
				<span>Loading </span>
				<span className="animate-pulse">...</span>
			</p>
		</div>
	);
}
