import { SettingsTable } from "./ninoxTableType";
import createSettingsRoutes from "./routes";
import { createNinoxRoutes, NinoxRequestInterceptor } from "../../../ninox";
import { createAxiosApi } from "../../../data/api";
import { AxiosResponseInterceptor } from "../../../data/interceptors";
import environment from "../../../misc/environment";

export default createSettingsRoutes({
	getAll: createNinoxRoutes<SettingsTable>({
		ninoxTeamId: environment.REACT_APP_NINOX_TEAM_ID,
		ninoxDatabaseId: environment.REACT_APP_NINOX_WEBSITE_DATABASE_ID,
		api: createAxiosApi({
			baseUrl: "https://api.ninoxdb.de/v1",
			requestInterceptors: [NinoxRequestInterceptor],
			responseInterceptors: [AxiosResponseInterceptor],
		}),
	}).getAll,
});
