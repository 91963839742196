import React, { PropsWithChildren } from "react";
import { PageHeaderProps, PrinterStatusProps } from "./props";
import { IconButtonProps } from "../../../models/props";
import { Icon, IconType } from "../../icons";
import renderSettingsModal from "../../../features/settings/components/settingsModal";

export default function PageWrapperHeader(props: PageHeaderProps) {
	return (
		<>
			{props.settingsModal && renderSettingsModal(props.settingsModal)}
			<div className="w-full h-20 bg-white dark:bg-black shadow">
				<div className="h-full max-w-7xl mx-auto flex items-center justify-between space-x-2 px-4 py-5 sm:px-6 sm:py-4 lg:px-8 md:justify-start">
					<div className="flex-grow">
						<div className="w-full flex items-center space-x-2">
							<p className="font-sans text-gray-900 text-xl sm:text-2xl dark:text-gray-200">
								<span className="font-regluar">von</span>
								<span className="font-extrabold">Luck</span>
							</p>
							<p className="text-xl sm:text-2xl font-hand text-gray-900 dark:text-gray-200">
								{props.locationName}
							</p>
						</div>
					</div>
					<div className="">
						<PrinterStatus {...props.printerStatus} />
					</div>
					<div className="">
						<Button {...props.settingsButton} />
					</div>
					<div className="">
						<Button {...props.logoutButton} />
					</div>
				</div>
			</div>
		</>
	);
}

export function Button(props: IconButtonProps) {
	return (
		<button onClick={props.onSelect} className="btn-2 btn-sm md:btn-md">
			<div className="flex items-center space-x-2">
				<Icon icon={props.icon} className="w-6 h-6" />
				<span className="sr-only sm:not-sr-only">{props.title}</span>
			</div>
		</button>
	);
}

export function PrinterStatus(props: PrinterStatusProps) {
	return (
		<div className="w-max">
			<button onClick={props.onSelect} className="btn-2 btn-sm md:btn-md">
				<ResolveStatusColor status={props.statusIcon}>
					<div className="w-min flex items-center space-x-2">
						<Icon icon={props.statusIcon} className="w-6 h-6" />
						<span className="min-w-4 flex-grow hidden sm:inline whitespace-nowrap overflow-ellipsis">
							{props.title}
						</span>
						<Icon icon={props.icon} className="w-6 h-6 sm:hidden" />
					</div>
				</ResolveStatusColor>
			</button>
		</div>
	);
}

export function ResolveStatusColor(props: PropsWithChildren<{ status: IconType }>) {
	switch (props.status) {
		case IconType.ONLINE:
			return <div className="text-green-500 dark:text-green-600">{props.children}</div>;
		case IconType.OFFLINE:
			return <div className="text-red-500 dark:text-red-500">{props.children}</div>;
		default:
			return <div className="text-gray-800 dark:text-gray-200">{props.children}</div>;
	}
}
