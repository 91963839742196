import { codeRegex, statusRegex, successRegex } from "./regex";

interface PrinterResponseMetaData {
	success: boolean;
	code: string;
	status: number;
}

export function getPrinterResponseMetaData(printerResponseXML: string): PrinterResponseMetaData {
	return {
		success: getRegexMatch(printerResponseXML, successRegex)?.groups[0] === "true",
		code: getRegexMatch(printerResponseXML, codeRegex)?.groups[0] || "",
		status: Number(getRegexMatch(printerResponseXML, statusRegex)?.groups[0] || "0"),
	};
}

export interface RegexMatch {
	match: string;
	groups: Array<string>;
}

export function getRegexMatch(value: string, regex: RegExp): RegexMatch | undefined {
	const match: Array<string> | null = value.match(regex);
	if (match === null) return undefined;
	return {
		match: match[0],
		groups: match.slice(1),
	};
}

export function getAllRegexMatches(value: string, regex: RegExp): Array<RegexMatch> {
	const allMatches = Array.from(value.matchAll(regex));
	return allMatches.map(([match, ...groups]) => {
		return { match, groups };
	});
}
