import React, { PropsWithChildren } from "react";

export default function PageWrapper(props: PropsWithChildren<{}>) {
	return (
		<div className="bg-white dark:bg-gray-900 ptn-light dark:ptn-dark w-full h-full p-10 flex items-center justify-center">
			<div className="bg-white dark:bg-gray-800 rounded-full shadow-md px-8 py-32 flex flex-col items-center justify-center space-y-3">
				{props.children}
			</div>
		</div>
	);
}
