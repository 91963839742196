import { ReducerAction } from "../../models/reducer";
import { AuthenticationActionTypes } from "./actionTypes";
import { AuthenticationStateType } from "./state";
import { Code } from "../models/code";

interface AuthenticationReducerAction<Payload> extends ReducerAction<AuthenticationActionTypes, Payload> {}

export interface SetAuthenticationType extends AuthenticationReducerAction<{ type: AuthenticationStateType }> {}

export function setAuthenticationType(type: AuthenticationStateType): SetAuthenticationType {
	return {
		type: AuthenticationActionTypes.SET_AUTHENTICATION_TYPE,
		payload: {
			type: type,
		},
	};
}

export interface SetPin extends AuthenticationReducerAction<{ pinIndex: number; value: number }> {}

export function setPin(pinIndex: number, value: number): SetPin {
	return {
		type: AuthenticationActionTypes.SET_PIN,
		payload: {
			pinIndex: pinIndex,
			value: value,
		},
	};
}

export interface SetPinCode extends AuthenticationReducerAction<{ pinCode: Code }> {}

export function setPinCode(pinCode: Code): SetPinCode {
	return {
		type: AuthenticationActionTypes.SET_PIN_CODE,
		payload: {
			pinCode: pinCode,
		},
	};
}
