import { IconProps } from "../models/domain";
import React from "react";

export default function JCB(props: IconProps) {
	return (
		<svg {...props} xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 496 496">
			<path
				fill="#1174CE"
				d="M68,69.2c-38.4,0-68,24.8-68,68v123.2c3.2,15.2,19.2,26.4,36.8,26.4c20,0,34.4-14.4,34.4-32v-62.4
	h54.4V254c0,34.4-44,52-76,52c-18.4,0-36-5.6-48.8-15.2v136h86.4c30.4,0,65.6-28.8,65.6-65.6v-292L68,69.2L68,69.2z"
			/>
			<path
				fill="#0F549D"
				d="M0,137.2v123.2c3.2,15.2,19.2,26.4,36.8,26.4c20,0,34.4-14.4,34.4-32v-62.4h54.4V254
	c0,34.4-44,52-76,52c-18.4,0-36-5.6-48.8-15.2v136h86.4c30.4,0,65.6-28.8,65.6-65.6v-292"
			/>
			<path
				fill="#02375E"
				d="M124.8,192.4V254c0,34.4-44,52-76,52c-18.4,0-36-5.6-48.8-15.2v136h86.4c30.4,0,65.6-28.8,65.6-65.6"
			/>
			<path fill="#146643" d="M370.4,285.2H408c9.6,0,18.4-8.8,18.4-18.4c0-9.6-8.8-18.4-18.4-18.4h-37.6V285.2z" />
			<path
				fill="#1BCC38"
				d="M412.8,69.2c-39.2,0-68,24.8-68,67.2v56h100.8c14.4,0,26.4,12,26.4,26.4s-12,26.4-26.4,26.4
	c16,0,28.8,11.2,28.8,25.6c0,14.4-12,25.6-28.8,25.6H344v130.4h86.4c30.4,0,65.6-28.8,65.6-65.6v-292L412.8,69.2L412.8,69.2z"
			/>
			<path
				fill="#329947"
				d="M344,137.2v55.2h100.8c14.4,0,26.4,12,26.4,26.4s-12,26.4-26.4,26.4c16,0,28.8,11.2,28.8,25.6
	c0,14.4-12,25.6-28.8,25.6H344v130.4h86.4c30.4,0,65.6-28.8,65.6-65.6v-292"
			/>
			<path
				fill="#146643"
				d="M471.2,218.8c0,14.4-12,26.4-26.4,26.4c16,0,28.8,11.2,28.8,25.6c0,14.4-12,25.6-28.8,25.6H344
		v130.4h86.4c30.4,0,65.6-28.8,65.6-65.6"
			/>
			<path
				fill="#146643"
				d="M425.6,222c0-9.6-8.8-18.4-18.4-18.4h-36.8v36H408C417.6,239.6,425.6,231.6,425.6,222z"
			/>
			<path
				fill="#E20E37"
				d="M239.2,69.2c-39.2,0-68,24.8-68,67.2v68.8c9.6-8,21.6-13.6,34.4-13.6h91.2v19.2
	c-21.6-4.8-42.4-7.2-52.8-7.2c-22.4,0-40.8,18.4-40.8,40.8c0,22.4,18.4,40.8,40.8,40.8c10.4,0,31.2-1.6,52.8-6.4v17.6h-91.2
	c-12.8,0-25.6-4.8-34.4-13.6v144h86.4c30.4,0,65.6-28.8,65.6-65.6v-292L239.2,69.2L239.2,69.2z"
			/>
			<path
				fill="#B41F36"
				d="M171.2,137.2V206c9.6-8,21.6-13.6,34.4-13.6h91.2v19.2c-21.6-4.8-42.4-7.2-52.8-7.2
	c-22.4,0-40.8,18.4-40.8,40.8S221.6,286,244,286c10.4,0,31.2-1.6,52.8-6.4v16.8h-91.2c-12.8,0-25.6-4.8-34.4-13.6v144h86.4
	c30.4,0,65.6-28.8,65.6-65.6v-292"
			/>
			<path
				fill="#720A1E"
				d="M296.8,192.4v19.2c-21.6-4.8-42.4-7.2-52.8-7.2c-22.4,0-40.8,18.4-40.8,40.8S221.6,286,244,286
	c10.4,0,31.2-1.6,52.8-6.4v16.8h-91.2c-12.8,0-25.6-4.8-34.4-13.6v144h86.4c30.4,0,65.6-28.8,65.6-65.6"
			/>
		</svg>
	);
}
