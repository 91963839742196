import React from "react";
import { OrderState } from "../models/domain";
import { StatusProps } from "./props";

export function Status(props: StatusProps) {
	function Title() {
		return (
			<div className="h-full flex items-center justify-center">
				<span className="sr-only">{props.title}</span>
			</div>
		);
	}

	switch (props.type) {
		case OrderState.PENDING:
			return (
				<div className="h-10 min-w-10 bg-red-600 py-1 px-2 rounded" title={props.title}>
					<Title />
				</div>
			);
		case OrderState.APPROVED:
			return (
				<div className="h-10 min-w-10 bg-yellow-600 py-1 px-2 rounded" title={props.title}>
					<Title />
				</div>
			);
		case OrderState.PREPARED:
			return (
				<div className="h-10 min-w-10 bg-blue-600 py-1 px-2 rounded" title={props.title}>
					<Title />
				</div>
			);
		case OrderState.COLLECTED:
			return (
				<div className="h-10 min-w-10 bg-green-600 py-1 px-2 rounded" title={props.title}>
					<Title />
				</div>
			);
		case OrderState.CANCELED:
			return (
				<div className="h-10 min-w-10 bg-gray-400 dark:bg-gray-600 py-1 px-2 rounded" title={props.title}>
					<Title />
				</div>
			);
		default:
			return null;
	}
}
