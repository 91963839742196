import Loading from "../components/loading/loading";
import React from "react";

export class LoadingPageProps {}

export function LoadingPage() {
	return (
		<div className="h-full w-full bg-white py-24 ptn-light dark:bg-black dark:ptn-dark flex items-center justify-center">
			<div className="h-auto w-min bg-white dark:bg-gray-900 rounded-full shadow-md">
				<Loading />
			</div>
		</div>
	);
}
