import { Order, OrderState, ProductInOrder } from "../models/domain";
import {
	CustomerProps,
	OrderDetailProps,
	OrderLoadingDetailProps,
	OrderMetaProps,
	OrderProps,
	ProductProps,
	ProductsProps,
	StatusProps,
} from "../components/props";
import { resolveOrderButtons } from "./resolveButtonProps";
import { formatDate } from "./utils";
import { OrderViewState } from "./toOrderListProps";

export interface OrderButtonActions {
	toggleDetails: () => void;
	approve: () => void;
	prepare: () => void;
	collect: () => void;
	cancel: () => void;
	print: () => void;
}

export function resolveOrderProps(order: Order, actions: OrderButtonActions, ordersState: OrderViewState): OrderProps {
	return {
		status: resolveOrderStatus(order.state),
		title: order.meta.customer.name,
		buttons: resolveOrderButtons(order.state, actions),
		details: resolveDetails(order, ordersState),
	};
}

function resolveDetails(
	order: Order,
	ordersState: OrderViewState
): OrderDetailProps | OrderLoadingDetailProps | undefined {
	switch (ordersState) {
		case OrderViewState.FETCHING:
			return { title: "loading" };
		case OrderViewState.EXPANDED:
			return {
				customer: resolveCustomerDetailProps(order),
				meta: resolveOrderMetaDetailProps(order),
				products: resolveProductsDetailProps(order),
			};
		default:
			return undefined;
	}
}

function resolveOrderStatus(orderState: OrderState): StatusProps {
	switch (orderState) {
		case OrderState.PENDING:
			return {
				type: OrderState.PENDING,
				title: "Offen",
			};
		case OrderState.APPROVED:
			return {
				type: OrderState.APPROVED,
				title: "Angenommen",
			};
		case OrderState.PREPARED:
			return {
				type: OrderState.PREPARED,
				title: "Abholbereit",
			};
		case OrderState.COLLECTED:
			return {
				type: OrderState.COLLECTED,
				title: "Abgeholt",
			};
		case OrderState.CANCELED:
			return {
				type: OrderState.CANCELED,
				title: "Widerrufen",
			};
		default:
			return {
				type: OrderState.LOADING,
				title: "Disabled",
			};
	}
}

function resolveProductsDetailProps(order: Order): ProductsProps {
	return {
		title: "Produkte",
		products: order.products.map(resolveProductProps),
		price: {
			title: "Preis(Summe)",
			value: "€ " + order.price.toFixed(2),
		},
	};
}

function resolveProductProps(product: ProductInOrder): ProductProps {
	return {
		title: product.name,
		amount: product.amount,
	};
}

function resolveCustomerDetailProps(order: Order): CustomerProps {
	return {
		title: "Kundeninformationen",
		name: order.meta.customer.name,
		phone: order.meta.customer.phone,
		mail: order.meta.customer.email,
	};
}

function resolveOrderMetaDetailProps(order: Order): OrderMetaProps {
	return {
		status: "status: " + resolveOrderStatus(order.state).title,
		title: "Bestellinformationen",
		date: formatDate(order.meta.date),
		time: order.meta.time,
		notes: order.meta.notes,
	};
}
