import React from "react";
import { ButtonProps, ButtonPropsType } from "../../../models/props";
import { ContentModalProps, LoadingModalProps } from "./props";

export default function CheckOrderActionModal(props: LoadingModalProps | ContentModalProps) {
	return (
		<div className="w-full h-full fixed top-0 left-0">
			<div className="w-full h-full fixed top-0 left-0 bg-black opacity-50 dark:opacity-75" />
			<div className="w-full h-full p-4 fixed top-0 left-0 flex items-center justify-center">
				{props instanceof LoadingModalProps ? <LoadingModal {...props} /> : <ModalWithContent {...props} />}
			</div>
		</div>
	);
}

export function LoadingModal(proxyProps: LoadingModalProps) {
	const props = proxyProps.content;
	return (
		<div className="min-w-1/5 w-full max-w-md p-10 rounded-lg bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 space-y-2">
			<h3 className="text-xl font-hand">{props.title}</h3>
		</div>
	);
}

export function ModalWithContent(proxyProps: ContentModalProps) {
	const props = proxyProps.content;
	return (
		<div className="min-w-1/5 w-full max-w-md p-10 rounded-lg bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 space-y-2">
			<h3 className="text-xl">{props.title}</h3>
			<p className="text-gray-600 dark:text-gray-400">{props.message}</p>
			<div className="w-full flex items-center justify-end space-x-2">
				<button tabIndex={0} onClick={props.buttons.cancel.onSelect} className="btn-2 btn-md">
					{props.buttons.cancel.title}
				</button>
				{resolveButton(props.buttons.approve)}
			</div>
		</div>
	);
}

function resolveButton(props: ButtonProps) {
	switch (props.type) {
		case ButtonPropsType.PRIME: {
			return (
				<button tabIndex={0} onClick={props.onSelect} className="btn-1 btn-md">
					{props.title}
				</button>
			);
		}

		case ButtonPropsType.DANGER: {
			return (
				<button tabIndex={0} onClick={props.onSelect} className="btn-danger btn-md">
					{props.title}
				</button>
			);
		}

		default: {
			return null;
		}
	}
}
