import React from "react";
import { PropsWithChildren } from "react";
import { HashLink } from "react-router-hash-link";

interface LinkProps {
	to: string;
	[key: string]: any;
}

export default function Link(props: PropsWithChildren<LinkProps>) {
	if (/((https?:\/\/)|tel:|mailto:)/.test(props.to)) {
		return (
			<a {...props} href={props.to} target="_blank" rel="noreferrer">
				{props.children}
			</a>
		);
	} else {
		return (
			<HashLink {...props} to={props.to}>
				{props.children}
			</HashLink>
		);
	}
}
