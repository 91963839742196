import {
	OrderResponseNumberState,
	OrderResponseStringState,
	ProductResponseWithAmount,
	OrderResponseWithResolvedProducts,
} from "./responseModels";
import { Order, OrderState, ProductInOrder } from "../models/domain";

export function toProductInOrder(productResponse: ProductResponseWithAmount): ProductInOrder {
	return new ProductInOrder({
		id: productResponse.id,
		name: productResponse.fields.name,
		price: productResponse.fields.price,
		amount: productResponse.amount,
	});
}

export function toOrder(orderResponse: OrderResponseWithResolvedProducts): Order {
	return new Order({
		id: orderResponse.id,
		state: toOrderState(orderResponse.fields.state),
		meta: {
			pickUpId: orderResponse.fields.orderPickUpId,
			date: orderResponse.fields.date,
			time: orderResponse.fields.time,
			customer: {
				name: orderResponse.fields.name,
				phone: orderResponse.fields.phone,
				email: orderResponse.fields.email,
			},
			notes: orderResponse.fields.notes,
		},
		products: orderResponse.products.map(toProductInOrder),
		price: orderResponse.fields.price,
	});
}

export function toOrderState(orderStateLike: OrderResponseNumberState | OrderResponseStringState): OrderState {
	switch (orderStateLike) {
		case OrderResponseNumberState.PENDING:
		case OrderResponseStringState.PENDING:
			return OrderState.PENDING;
		case OrderResponseNumberState.APPROVED:
		case OrderResponseStringState.APPROVED:
			return OrderState.APPROVED;
		case OrderResponseNumberState.PREPARED:
		case OrderResponseStringState.PREPARED:
			return OrderState.PREPARED;
		case OrderResponseNumberState.COLLECTED:
		case OrderResponseStringState.COLLECTED:
			return OrderState.COLLECTED;
		case OrderResponseNumberState.CANCELED:
		case OrderResponseStringState.CANCELED:
			return OrderState.CANCELED;
		default:
			return OrderState.LOADING;
	}
}

export function toOrderResponseState(orderState: OrderState): OrderResponseNumberState {
	switch (orderState) {
		case OrderState.APPROVED:
			return OrderResponseNumberState.APPROVED;
		case OrderState.PREPARED:
			return OrderResponseNumberState.PREPARED;
		case OrderState.COLLECTED:
			return OrderResponseNumberState.COLLECTED;
		case OrderState.CANCELED:
			return OrderResponseNumberState.CANCELED;
		default:
			return OrderResponseNumberState.PENDING;
	}
}
