import ReactDOM from "react-dom";
import React, { PropsWithChildren } from "react";
import { Icon, IconType } from "../../../components/icons";
import { ButtonProps, ButtonPropsType, IconButtonProps, TextInputProps } from "../../../models/props";
import Menu from "../../../components/menu";

export interface SettingsModalProps {
	title: string;
	message: string;
	settings: {
		location: {
			title: string;
			errorMessage?: string;
			input: SelectInputProps;
		};
		pinCode: {
			title: string;
			errorMessage?: string;
			input: LockedInputProps;
		};
		printerIp: {
			title: string;
			errorMessage?: string;
			input: IPv4InputProps;
		};
	};
	buttons: {
		cancel: ButtonProps;
		approve: ButtonProps;
	};
}

export interface SelectInputProps {
	header: {
		title: string;
		icon: IconType;
	};
	content: {
		locations: Array<ButtonProps>;
	};
}

export interface LockedInputProps {
	input: TextInputProps;
	lock: IconButtonProps;
}

export interface IPv4InputProps {
	label: string;
	fieldSetId: string;
	fieldSetName: string;
	inputs: [TextInputProps, TextInputProps, TextInputProps, TextInputProps];
}

export default function renderSettingsModal(props: SettingsModalProps) {
	return ReactDOM.createPortal(<SettingsModal {...props} />, document.getElementById("root")!);
}

export function SettingsModal(props: SettingsModalProps) {
	return (
		<div className="w-full h-full fixed top-0 left-0" tabIndex={0}>
			<div className="w-full h-full fixed top-0 left-0 bg-black opacity-50 dark:opacity-75" />
			<div className="w-full h-full p-4 fixed top-0 left-0 flex items-center justify-center">
				<ModalContent {...props} />
			</div>
		</div>
	);
}

export function ModalContent(props: SettingsModalProps) {
	return (
		<div className="min-w-1/5 max-w-2xl p-10 rounded-lg bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-200 space-y-8">
			<h3 className="text-3xl md:text-5xl font-bold font-serif text-gray-700 dark:text-gray-300">
				{props.title}
			</h3>
			<p className="text-gray-600 dark:text-gray-400">{props.message}</p>
			<div>
				<dl className="space-y-2">
					<InputSection
						title={props.settings.location.title}
						errorMessage={props.settings.location.errorMessage}
					>
						<LocationInput {...props.settings.location.input} />
					</InputSection>
					<InputSection
						title={props.settings.pinCode.title}
						errorMessage={props.settings.pinCode.errorMessage}
					>
						<LockedInput {...props.settings.pinCode.input} />
					</InputSection>
					<InputSection
						title={props.settings.printerIp.title}
						errorMessage={props.settings.printerIp.errorMessage}
					>
						<IPv4Input {...props.settings.printerIp.input} />
					</InputSection>
				</dl>
			</div>
			<div className="w-full flex items-center justify-end space-x-2">
				{resolveButton(props.buttons.cancel)}
				{resolveButton(props.buttons.approve)}
			</div>
		</div>
	);
}

function InputSection(props: PropsWithChildren<{ title: string; errorMessage?: string }>) {
	return (
		<div className="sm:grid sm:grid-cols-2 sm:gap-2">
			<dt className="self-stretch">
				<label>{props.title}</label>
			</dt>
			<dd className="self-stretch">{props.children}</dd>
			{props.errorMessage && <p className="text-bold text-red-600 text-right col-span-2">{props.errorMessage}</p>}
		</div>
	);
}

function LocationInput(props: SelectInputProps) {
	return (
		<Menu
			header={({ menuState, toggleMenuState }) => (
				<button className="w-full btn-2 btn-md flex items-center" onClick={toggleMenuState}>
					<p className="flex-grow text-center">{props.header.title}</p>
					<span className="sr-only">{props.header.title}</span>
					<Icon
						icon={menuState ? IconType.CHEVRON_DOWN_STROKE : IconType.CHEVRON_LEFT_STROKE}
						className="w-5 h-5"
					/>
				</button>
			)}
			content={({ toggleMenuState }) => (
				<>
					{props.content.locations.map((buttonProps, index) => {
						const clickHandler = () => {
							buttonProps.onSelect();
							toggleMenuState();
						};
						return (
							<button
								key={index + 1}
								role="menuitem"
								tabIndex={-1}
								onClick={clickHandler}
								className="w-full px-4 py-2 text-gray-700 dark:text-gray-300 bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 text-left text-sm"
							>
								{buttonProps.title}
							</button>
						);
					})}
				</>
			)}
		/>
	);
}

function LockedInput(props: LockedInputProps) {
	return (
		<div className="w-full flex items-center space-x-2">
			<div className="w-full">
				<label htmlFor={props.input.fieldId} className="sr-only">
					{props.input.label}
				</label>
				<input
					type="text"
					className="w-full bg-white dark:bg-gray-800 px-1 py-1 rounded-md text-mono text-lg text-center text-gray-900 dark:text-gray-100 focus:outline-none focus:ring focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-green-400 dark:focus:ring-green-600"
					id={props.input.fieldId}
					name={props.input.fieldName}
					value={props.input.value}
					placeholder={props.input.placeholder}
					onChange={(event) => props.input.onChange(event.target.value)}
					onBlur={props.lock.onSelect}
				/>
			</div>
			<button onClick={props.lock.onSelect} className="btn-2 btn-md flex items-center">
				<span className="sr-only">{props.lock.title}</span>
				<Icon icon={props.lock.icon} className="h-5 w-5 text-gray-900 dark:text-gray-100" />
			</button>
		</div>
	);
}

function IPv4Input(props: IPv4InputProps) {
	return (
		<div className="flex items-center justify-between space-x-1">
			{props.inputs.map((textInputProps, index) => {
				return (
					<React.Fragment key={index + 1}>
						{index > 0 && <span className="text-4xl font-mono text-gray-600 dark:text-gray-400">.</span>}
						<IPv4TextInput {...textInputProps} />
					</React.Fragment>
				);
			})}
		</div>
	);
}

function IPv4TextInput(props: TextInputProps) {
	return (
		<div className="w-full">
			<label htmlFor={props.fieldId} className="sr-only">
				{props.label}
			</label>
			<input
				type="text"
				className="w-full bg-white dark:bg-gray-800 px-1 py-1 rounded-md text-mono text-lg text-center text-gray-900 dark:text-gray-100 focus:outline-none focus:ring focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-green-400 dark:focus:ring-green-600"
				id={props.fieldId}
				name={props.fieldName}
				value={props.value}
				placeholder={props.placeholder}
				onChange={(event) => props.onChange(event.target.value)}
			/>
		</div>
	);
}

function resolveButton(props: ButtonProps) {
	switch (props.type) {
		case ButtonPropsType.PRIME: {
			return (
				<button tabIndex={0} onClick={props.onSelect} className="btn-1 btn-md">
					{props.title}
				</button>
			);
		}

		case ButtonPropsType.SEC: {
			return (
				<button tabIndex={0} onClick={props.onSelect} className="btn-2 btn-md">
					{props.title}
				</button>
			);
		}

		case ButtonPropsType.DANGER: {
			return (
				<button tabIndex={0} onClick={props.onSelect} className="btn-danger btn-md">
					{props.title}
				</button>
			);
		}

		default: {
			return null;
		}
	}
}
