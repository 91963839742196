import { Order, ProductInOrder } from "../../orders/models/domain";
import { formatShortDate } from "../../orders/selectors/utils";

export const printerXMLDocumentWrapper = (innerXML: string) => `
<?xml version="1.0" encoding="UTF-8"?>
<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/">
    <s:Body>
        <epos-print xmlns="http://www.epson-pos.com/schemas/2011/03/epos-print">
            ${innerXML}
        </epos-print>
    </s:Body>
</s:Envelope>
`;

export const printerStatusJob = () => printerXMLDocumentWrapper("");

export const orderPrintJob = (order: Order): string =>
	printerXMLDocumentWrapper(`
<text linespc="200" />
<feed line="3" />
<text width="5" height="5" reverse="true" align="center"> ${order.meta.pickUpId} </text>
<feed line="2" />
<text reverse="false" />
<text width="3" height="3" align="center">${order.meta.customer.name}</text>
<feed line="1" />
<text width="2" height="2" align="center">${formatShortDate(order.meta.date)}</text>
<feed line="1" />
<text width="2" height="2" align="center">${order.meta.time}</text>
<feed line="2" />
<text linespc="50" />
<text width="1" height="1" align="center">${order.meta.customer.phone || "---"}</text>
<feed line="1" />
<text width="1" height="1" align="center">${order.meta.customer.email || "---"}</text>
<feed line="1" />
<text linespc="100" />
<feed line="1" />
<text width="2" height="2" align="center">Bestellung</text>
<feed line="1" />
<text width="1" height="1" align="left">________________________________________________</text>
<feed line="1" />
${createProductRows(order.products)}
<text width="1" height="1" align="left">________________________________________________</text>
<feed line="1" />
<text linespc="100" />
<text width="2" height="2" align="right">${calcOrderPrice(order)}</text>
<feed line="1" />
<text width="1" height="1" align="left">================================================</text>
<feed line="1" />
<text linespc="200" />
<feed line="3" />
<cut />
`);

//<feed line="2" />
//<text width="5" height="5" align="center">Bezahlt</text>

const calcOrderPrice = (order: Order) => {
	return (
		"€ " +
		order.products
			.reduce((orderPrice, product) => {
				return orderPrice + product.price * product.amount;
			}, 0)
			.toFixed(2)
	);
};

const createProductRows = (products: Array<ProductInOrder>) => {
	return products.reduce((rows, product) => {
		return (
			rows +
			`<text width="1" height="1" align="left">${createProductRow(
				product.name,
				product.amount
			)}</text><feed line="1" />`
		);
	}, "");
};

const charactersPerLine = 48;

const createProductRow = (title: string, amount: number) => {
	return (
		title +
		Array(charactersPerLine - title.length - String(amount).length)
			.fill(".")
			.join("") +
		String(amount)
	);
};
