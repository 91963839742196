// https://medium.com/shyftplan-techblog/typescript-advanced-types-199ff1f3e3e8

export class MissingEnvironmentVariableError extends Error {
	constructor(environmentVariable: string) {
		const message = `MissingEnvironmentVariableError: the environment variable "${environmentVariable}" is missing from process.env`;
		super(message);
	}
}

enum EnvironmentVariableNames {
	NODE_ENV,
	REACT_APP_MASTER_PW_PUBLIC_KEY,
	REACT_APP_MASTER_PW_PRIVATE_KEY,
	REACT_APP_LOCAL_STORAGE_NAME_SPACE,
	REACT_APP_NINOX_API_KEY,
	REACT_APP_NINOX_TEAM_ID,
	REACT_APP_NINOX_PRODUCTS_DATABASE_ID,
	REACT_APP_NINOX_WEBSITE_DATABASE_ID,
}

type EnvironmentVariables = {
	[key in keyof typeof EnvironmentVariableNames]: string;
};

export function getEnvironmentVariables(processEnv: any): EnvironmentVariables {
	return Object.keys(EnvironmentVariableNames)
		.slice(Object.keys(EnvironmentVariableNames).length / 2)
		.reduce((environmentVariables, environmentVariableName) => {
			if (!processEnv[environmentVariableName]) {
				throw new MissingEnvironmentVariableError(environmentVariableName);
			}
			return {
				...environmentVariables,
				[environmentVariableName]: processEnv[environmentVariableName],
			};
		}, {} as EnvironmentVariables);
}

export default getEnvironmentVariables(process.env);
