import { createPrinterActions } from "./actions";
import { dispatchAppStateAction } from "../../../appState";
import printerRoutes from "../printerRoutes";

const printerActions = createPrinterActions({
	dispatchAppStateAction: dispatchAppStateAction,
	getPrinterNetworkState: printerRoutes.getPrinterNetworkState,
	printOrder: (order) => printerRoutes.printOrder(order).then(),
});

export default printerActions;
