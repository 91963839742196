import { OrderProps, OrderSectionProps } from "./props";
import React from "react";
import { Order } from "./order";

export function OrderSection(props: OrderSectionProps) {
	return (
		<section className="space-y-4">
			<h3 className="w-full text-2xl text-left text-gray-800 dark:text-gray-200">{props.title}</h3>
			<ul className="space-y-3">
				{props.orders.map((orderProps: OrderProps, index) => {
					return <Order key={index + 1} {...orderProps} />;
				})}
			</ul>
		</section>
	);
}
