import React from "react";
import { ButtonProps, TextInputProps } from "../../../models/props";
import useAskForMasterPasswordProps from "../selectors/useAskForMasterPassword";
import { focusElement } from "../../../utils/view";
import PageWrapper from "../components/pageWrapper";

export default function AskForPinCodePage() {
	const viewProps = useAskForMasterPasswordProps();
	return <AskForMasterPassword {...viewProps} />;
}

export interface AskForMasterPasswordProps {
	title: string;
	subTitle: string;
	input: TextInputProps;
	errorMessage?: string;
	submitButton?: ButtonProps;
}

export function AskForMasterPassword(props: AskForMasterPasswordProps) {
	React.useEffect(() => {
		focusElement(props.input.fieldId);
		// eslint-disable-next-line
	}, []);

	return (
		<PageWrapper>
			<div className="w-64 flex flex-col items-center space-y-4">
				<h3 className="text-gray-900 dark:text-gray-100 text-2xl font-medium text-center">{props.title}</h3>
				<TextInput {...props.input} />
				<p className="text-center text-gray-400">{props.subTitle}</p>
				{props.errorMessage && <p className="text-center text-red-500">{props.errorMessage}</p>}
				{props.submitButton && (
					<button onClick={props.submitButton.onSelect} className="btn-1 btn-md">
						{props.submitButton.title}
					</button>
				)}
			</div>
		</PageWrapper>
	);
}

function TextInput(props: TextInputProps) {
	return (
		<div className="">
			<label htmlFor={props.fieldId} className="sr-only">
				{props.label}
			</label>
			<input
				type="text"
				className="w-full bg-white dark:bg-gray-800 py-4 px-1 rounded text-mono text-lg text-center text-gray-900 dark:text-gray-100 focus:outline-none focus:ring focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-green-400 dark:focus:ring-green-600"
				id={props.fieldId}
				name={props.fieldName}
				value={props.value}
				placeholder={props.placeholder}
				onChange={(event) => props.onChange(event.target.value)}
			/>
		</div>
	);
}
