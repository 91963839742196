import { SocialIconOption, SocialMediaIconType } from "./models/domain";
import Tripadvisor from "./components/tripadvisor";
import TooGoodToGo from "./components/tooGoodToGo";
import Yelp from "./components/yelp";

const socialMediaIconOptions: Array<SocialIconOption> = [
	{
		type: SocialMediaIconType.TRIPADVISOR,
		icon: Tripadvisor,
	},
	{
		type: SocialMediaIconType.TOOOGOODTOGO,
		icon: TooGoodToGo,
	},
	{
		type: SocialMediaIconType.YELP,
		icon: Yelp,
	},
];

export default socialMediaIconOptions;
