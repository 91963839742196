import ReactDOM from "react-dom";
import React from "react";
import ErrorBoundary from "./misc/errorBoundary";
import "./styles";
import { AppStateProvider } from "./appState";
import PinProtectedApplication from "./application";
import reportWebVitals from "./reportWebVitals";
import ResolveRequiredSettings from "./features/setup";

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			<AppStateProvider>
				<ResolveRequiredSettings>
					<PinProtectedApplication />
				</ResolveRequiredSettings>
			</AppStateProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
