import { GetPrinterState, PrinterActions, PrintOrder } from "./models";
import { PrinterNetworkState } from "../reducer/state";
import { Order } from "../../orders/models/domain";
import * as reducerActions from "../reducer/reducerActions";

interface GetPrinterNetworkState {
	(): Promise<PrinterNetworkState>;
}

interface PrinterActionDependencies {
	dispatchAppStateAction: (action: any) => void;
	getPrinterNetworkState: GetPrinterNetworkState;
	printOrder: (order: Order) => Promise<void>;
}

export function createPrinterActions(dependencies: PrinterActionDependencies): PrinterActions {
	const getPrinterState: GetPrinterState = () => {
		dependencies.dispatchAppStateAction(reducerActions.setPrinterNetworkState(PrinterNetworkState.CHECKING));
		return dependencies
			.getPrinterNetworkState()
			.then((printerNetworkState) => {
				dependencies.dispatchAppStateAction(reducerActions.setPrinterNetworkState(printerNetworkState));
			})
			.catch(() => {
				dependencies.dispatchAppStateAction(reducerActions.setPrinterNetworkState(PrinterNetworkState.OFFLINE));
			});
	};

	const printOrder: PrintOrder = (order) => {
		return dependencies.printOrder(order);
	};

	return {
		getPrinterState,
		printOrder,
	};
}
