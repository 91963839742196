import { Api } from "../../../data/models";
import { Order } from "../../orders/models/domain";
import { PrinterNetworkState } from "../reducer/state";
import { getPrinterResponseMetaData } from "./responseParser";
import { orderPrintJob, printerStatusJob } from "./xmlBodies";

interface GetPrinterNetworkState {
	(): Promise<PrinterNetworkState>;
}

interface PrintOrder {
	(order: Order): Promise<PrinterNetworkState>;
}

export interface PrinterRoutes {
	getPrinterNetworkState: GetPrinterNetworkState;
	printOrder: PrintOrder;
}

interface PrinterRouteDependencies {
	getApi: () => Api;
}

export default function createPrinterRoutes(dependencies: PrinterRouteDependencies): PrinterRoutes {
	const getPrinterNetworkState: GetPrinterNetworkState = () => {
		return dependencies
			.getApi()
			.xmlPost("", printerStatusJob())
			.then(getPrinterResponseMetaData)
			.then((printerResponseMetaData) => {
				if (printerResponseMetaData.success) return PrinterNetworkState.ONLINE;
				return PrinterNetworkState.OFFLINE;
			});
	};

	const printOrder: PrintOrder = (order) => {
		return dependencies
			.getApi()
			.xmlPost("", orderPrintJob(order))
			.then(getPrinterResponseMetaData)
			.then((printerResponseMetaData) => {
				if (printerResponseMetaData.success) return PrinterNetworkState.ONLINE;
				return PrinterNetworkState.OFFLINE;
			});
	};

	return {
		getPrinterNetworkState,
		printOrder,
	};
}

// var asb = res[0].getAttribute('status'); if (asb & 0x00000001) {
//     msg += ' No printer response\n'; }
// if (asb & 0x00000002) {
//     msg += ' Print complete\n';
// }
// if (asb & 0x00000004) {
//     msg += ' Status of the drawer kick number 3 connector pin = "H"\n'; }
// if (asb & 0x00000008) {
//     msg += ' Offline status\n';
// }
// if (asb & 0x00000020) {
//     msg += ' Cover is open\n'; }
// if (asb & 0x00000040) {
//     msg += ' Paper feed switch is feeding paper\n';
// }
// if (asb & 0x00000100) {
//     msg += ' Waiting for online recovery\n'; }
// if (asb & 0x00000200) {
//     msg += ' Panel switch is ON\n';
// }
// if (asb & 0x00000400) {
//     msg += ' Mechanical error generated\n'; }
// if (asb & 0x00000800) {
//     msg += ' Auto cutter error generated\n';
// }
// if (asb & 0x00002000) {
//     msg += ' Unrecoverable error generated\n'; }
// if (asb & 0x00004000) {
//     msg += ' Auto recovery error generated\n';
// }
// if (asb & 0x00020000) {
//     msg += ' No paper in the roll paper near end detector\n'; }
// if (asb & 0x00080000) {
//     msg += ' No paper in the roll paper end detector\n';
// }
// if (asb & 0x80000000) {
//     msg += ' Stop the spooler\n'; }
