import React from "react";
import { ButtonProps, IconButtonProps } from "../../../models/props";
import { LoadingPage, LoadingPageProps } from "../../../commonPages/loading";
import { Icon, IconType } from "../../../components/icons";
import Menu from "../../../components/menu";
import useAskForLocationProps from "../selectors/useAskForLocationProps";
import PageWrapper from "../components/pageWrapper";

export default function AskForLocationPage() {
	const viewProps = useAskForLocationProps();
	if (viewProps instanceof LoadingPageProps) return <LoadingPage />;
	return <AskForLocation {...viewProps} />;
}

export interface AskForLocationContentProps {
	title: string;
	selectLocation: {
		header: {
			title: string;
			expansionButton: IconButtonProps;
		};
		content: {
			locations: Array<Array<ButtonProps>>;
		};
	};
	submitButton?: ButtonProps;
}

export function AskForLocation(props: AskForLocationContentProps) {
	return (
		<PageWrapper>
			<h3 className="text-gray-900 dark:text-gray-100 text-2xl font-medium text-center">{props.title}</h3>
			<div className="space-y-2">
				<Menu
					header={({ menuState, toggleMenuState }) => (
						<button onClick={toggleMenuState} className="btn-2 btn-md">
							<div className="flex items-center space-x-2">
								<p>{props.selectLocation.header.title}</p>
								<span className="sr-only">{props.selectLocation.header.expansionButton.title}</span>
								<Icon
									icon={menuState ? IconType.CHEVRON_DOWN_STROKE : IconType.CHEVRON_LEFT_STROKE}
									className="w-5 h-5"
								/>
							</div>
						</button>
					)}
					content={({ toggleMenuState }) => (
						<>
							{props.selectLocation.content.locations.map((items, index) => {
								return (
									<div key={index + 1} className="py-1" role="none">
										{items.map((buttonProps, index) => {
											const clickHandler = () => {
												buttonProps.onSelect();
												toggleMenuState();
											};
											return (
												<button
													key={index + 1}
													role="menuitem"
													tabIndex={-1}
													onClick={clickHandler}
													className="w-full px-4 py-2 text-gray-700 dark:text-gray-300 bg-transparent hover:bg-gray-300 dark:hover:bg-gray-700 text-left text-sm"
												>
													{buttonProps.title}
												</button>
											);
										})}
									</div>
								);
							})}
						</>
					)}
				/>
			</div>
			{props.submitButton && (
				<button onClick={props.submitButton.onSelect} className="btn-1 btn-md">
					{props.submitButton.title}
				</button>
			)}
		</PageWrapper>
	);
}
