import React from "react";
import { Code } from "../../../authentication/models/code";
import { IPv4 } from "../../../features/settings/models/ipv4";
import { IconType } from "../../icons";
import { PrinterNetworkState } from "../../../features/printer/reducer/state";
import { LocationInState } from "../../../features/settings/reducer/state";
import { PageWrapperProps, PrinterStatusProps } from "../components/props";
import { SettingsModalProps } from "../../../features/settings/components/settingsModal";
import { ButtonPropsType, TextInputProps } from "../../../models/props";
import usePrinterState from "../../../features/printer/selectors/usePrinterState";
import useSettingsState from "../../../features/settings/selectors/useSettingsState";
import settingsActions from "../../../features/settings/actions";
import authenticationActions from "../../../authentication/actions";
import { orderActions } from "../../../features/orders/actions";
import printerActions from "../../../features/printer/actions";
import settingsRoutes from "../../../features/settings/routes";

const IPv4Placeholder = ["192", "168", "178", "158"];

export default function usePageWrapperProps(): PageWrapperProps {
	const [locations, setLocations] = React.useState<Array<LocationInState>>([]);
	const [showSettingsModal, setSettingsModalVisibility] = React.useState<boolean>(false);
	const [isPinCodeInputLocked, setPinCodeLock] = React.useState<boolean>(true);
	const { location, pinCode, printerIp } = useSettingsState();
	const { networkState } = usePrinterState();
	const [settingsLocation, setSettingsLocation] = React.useState<LocationInState | undefined>(location || undefined);
	const [settingsPinCode, setSettingsPinCode] = React.useState<string>(pinCode || "");
	const [settingsPrinterIP, setSettingsPrinterIp] = React.useState<string>(printerIp || "...");
	const [settingsPinCodeErrorMessage, setSettingsPinCodeErrorMessage] = React.useState<string | undefined>(undefined);
	const [settingsPrinterIpErrorMessage, setSettingsPrinterIpErrorMessage] = React.useState<string | undefined>(
		undefined
	);
	const toggleSettingsModal = () => setSettingsModalVisibility((prev) => !prev);

	async function cancel() {
		setSettingsPinCode(pinCode || "");
		setSettingsPrinterIp(printerIp || "");
		setSettingsLocation(location || undefined);
		toggleSettingsModal();
	}

	async function save() {
		try {
			settingsActions.setPinCode(new Code(settingsPinCode));
			settingsActions.setPrinterIp(new IPv4(settingsPrinterIP));
			if (settingsLocation !== undefined) settingsActions.setLocation(settingsLocation);
			await Promise.all([orderActions.fetchAllOrders(), printerActions.getPrinterState()]);
			toggleSettingsModal();
		} catch (error) {
			if (error instanceof Code.InvalidCodeError) {
				setSettingsPinCodeErrorMessage("Der Pin Code muss aus 4 bis 8 Zahlen bestehen.");
			}

			if (error instanceof IPv4.InValidIPv4Error) {
				setSettingsPrinterIpErrorMessage(
					"Die Drucker IP sollte eine IPv4 Addresse sein (0.0.0.0 - 255.255.255.255)."
				);
			}
		}
	}

	function getLocations(): Promise<void> {
		return settingsRoutes.getAllLocations().then((locations) => setLocations(locations));
	}

	function resolvePinCodeInput() {
		if (isPinCodeInputLocked) {
			return {
				fieldId: "pin-code",
				fieldName: "pin-code",
				label: "Pin Code",
				value: "****",
				onChange: (value: string) => {},
				placeholder: "0000",
			};
		} else {
			return {
				fieldId: "pin-code",
				fieldName: "pin-code",
				label: "Pin Code",
				value: settingsPinCode || "",
				onChange: (value: string) => {
					if (/^\d*$/.test(value) && value.length < 9) {
						setSettingsPinCodeErrorMessage(undefined);
						setSettingsPinCode(value);
					}
				},
				placeholder: "0000",
			};
		}
	}

	function resolveSettingsModal(): SettingsModalProps | undefined {
		if (!showSettingsModal) return undefined;
		return {
			title: "Einstellungen",
			message:
				"Hier können Einstellungen zur vonLuck Location App vorgenommen werden. Diese Einstellungen werden local im Browser gespeichert.",
			settings: {
				location: {
					title: "Location",
					input: {
						header: {
							icon: IconType.DEFAULT,
							title: settingsLocation?.name || "Wähle deine vonLuck Location",
						},
						content: {
							locations: [
								...locations.map((location) => {
									return {
										title: location.name,
										onSelect: () => setSettingsLocation(location),
									};
								}),
							],
						},
					},
				},
				pinCode: {
					title: "Pin Code",
					errorMessage: settingsPinCodeErrorMessage,
					input: {
						input: resolvePinCodeInput(),
						lock: {
							title: "Pin Code Lock",
							icon: isPinCodeInputLocked ? IconType.LOCK_CLOSED_STROKE : IconType.LOCK_OPEN_STROKE,
							onSelect: () => setPinCodeLock((prev) => !prev),
						},
					},
				},
				printerIp: {
					title: "Drucker IP-Adresse",
					errorMessage: settingsPrinterIpErrorMessage,
					input: {
						label: "Drucker IP-Adresse",
						fieldSetId: "printer-ip",
						fieldSetName: "printer-ip",
						inputs: [
							...Array(4)
								.fill(null)
								.map((_, index): TextInputProps => {
									return {
										fieldId: "printer-ip-element-" + (index + 1),
										fieldName: "printer-ip-element-" + (index + 1),
										label: "Drucker IPv4 Element",
										placeholder: IPv4Placeholder[index],
										value: settingsPrinterIP.split(".")[index],
										onChange: (value: string) => {
											if (/^\d*$/.test(value)) {
												const newIPv4Address = Array(4)
													.fill(null)
													.map((_, elementIndex) => {
														if (elementIndex === index) {
															return value;
														} else {
															return settingsPrinterIP.split(".")[elementIndex];
														}
													})
													.join(".");
												setSettingsPrinterIp(newIPv4Address);
											}
										},
									};
								}),
						] as [TextInputProps, TextInputProps, TextInputProps, TextInputProps],
					},
				},
			},
			buttons: {
				cancel: {
					title: "Abbrechen",
					onSelect: cancel,
					type: ButtonPropsType.SEC,
				},
				approve: {
					title: "Speichern",
					onSelect: save,
					type: ButtonPropsType.PRIME,
				},
			},
		};
	}

	React.useEffect(() => {
		getLocations();
		printerActions.getPrinterState();
		// eslint-disable-next-line
	}, []);

	return {
		pageHeader: {
			locationName: location?.name || "",
			navigation: [],
			logoutButton: {
				icon: IconType.LOGOUT,
				title: "Abmelden",
				onSelect: () => authenticationActions.logout(),
			},
			settingsButton: {
				icon: IconType.SETTINGS,
				title: "Einstellungen",
				onSelect: toggleSettingsModal,
			},
			printerStatus: resolvePrinterStateProps(networkState),
			settingsModal: resolveSettingsModal(),
		},
		pageFooter: {
			copyright: "© 2021 vonLuck, All rights reserved.",
			legal:
				"Michael & Claudia Mindak, vonLuck (Einzelunternehmen).\n" +
				" Kontakt via E-Mail(cafe@von-luck.de).\n" +
				" Adresse Von-Luck-Straße 27, 14129 Berlin Nikolassee Deutschland",
		},
	};
}

function resolvePrinterStateProps(state: PrinterNetworkState): PrinterStatusProps {
	switch (state) {
		case PrinterNetworkState.CHECKING:
			return {
				statusIcon: IconType.REFRESH,
				statusTitle: "Warten Auf Drucker ...",
				icon: IconType.PRINT,
				title: "Warten Auf Drucker ...",
				onSelect: () => {},
			};
		case PrinterNetworkState.OFFLINE:
			return {
				statusIcon: IconType.OFFLINE,
				statusTitle: "Drucker Nicht Verbunden",
				icon: IconType.PRINT,
				title: "Drucker Nicht Verbunden",
				onSelect: printerActions.getPrinterState,
			};
		case PrinterNetworkState.ONLINE:
			return {
				statusIcon: IconType.ONLINE,
				statusTitle: "Drucker Verbunden",
				icon: IconType.PRINT,
				title: "Drucker Verbunden",
				onSelect: printerActions.getPrinterState,
			};
	}
}
