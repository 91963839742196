import { Reducer } from "../../../models/reducer";
import { PrinterNetworkState, PrinterState } from "./state";
import * as reducerActions from "./reducerActions";

function createPrinterState(): PrinterState {
	return {
		networkState: PrinterNetworkState.OFFLINE,
	};
}

interface PrinterReducer<Action = any> extends Reducer<PrinterState, Action> {}

export const printerReducer: PrinterReducer = (state = createPrinterState(), action) => {
	switch (action.type) {
		case reducerActions.PrinterActionTypes.SET_PRINTER_NETWORK_STATE:
			return {
				...state,
				networkState: action.payload.networkState,
			};
		default:
			return { ...state };
	}
};
