import { Code } from "../../../authentication/models/code";
import { IPv4 } from "../models/ipv4";
import { LocationInState } from "../reducer/state";
import * as settingsReducerActions from "../reducer/reducerActions";
import { MasterPassword } from "../models/masterPassword";

interface SettingsActionsDependencies {
	dispatchAppStateAction: (action: any) => void;
}

interface SetLocation {
	(locationId: LocationInState): void;
}

interface SetPinCode {
	(pinCode: Code): void;
}

interface SetPrinterIp {
	(printerIp: IPv4): void;
}

interface SetMasterPasswordResult {
	(masterPasswordResult: string): void;
}

interface LoginWithMasterPassword {
	(masterPasswordPublicKey: string): void;
}

export interface SettingsActions {
	setLocation: SetLocation;
	setPinCode: SetPinCode;
	setPrinterIp: SetPrinterIp;
	setMasterPasswordResult: SetMasterPasswordResult;
	loginWithMasterPassword: LoginWithMasterPassword;
}

export default function createSettingsActions(dependencies: SettingsActionsDependencies): SettingsActions {
	const setLocation: SetLocation = (location) => {
		dependencies.dispatchAppStateAction(settingsReducerActions.setLocation(location));
	};

	const setPinCode: SetPinCode = (pinCode) => {
		dependencies.dispatchAppStateAction(settingsReducerActions.setPinCode(pinCode));
	};

	const setPrinterIp: SetPrinterIp = (printerIp) => {
		dependencies.dispatchAppStateAction(settingsReducerActions.setPrinterIp(printerIp));
	};

	const setMasterPasswordResult: SetMasterPasswordResult = (masterPasswordResult) => {
		dependencies.dispatchAppStateAction(settingsReducerActions.setMasterPasswordResult(masterPasswordResult));
	};

	const loginWithMasterPassword: LoginWithMasterPassword = (masterPasswordPublicKey: string) => {
		if (MasterPassword.validatePublicKey(masterPasswordPublicKey)) {
			dependencies.dispatchAppStateAction(
				settingsReducerActions.setMasterPasswordResult(
					MasterPassword.createMasterPasswordResultFromPublicKey(masterPasswordPublicKey)
				)
			);
		}
	};

	return {
		setLocation,
		setPinCode,
		setPrinterIp,
		setMasterPasswordResult,
		loginWithMasterPassword,
	};
}
