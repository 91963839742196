import React, { PropsWithChildren } from "react";
import { PageWrapperProps } from "./props";
import PageFooter from "./pageFooter";
import PageWrapperHeader from "./pageHeader";

export default function PageWrapper(props: PropsWithChildren<PageWrapperProps>) {
	return (
		<>
			<PageWrapperHeader {...props.pageHeader} />
			<div className="min-h-full h-auto bg-white sm:py-8 md:py-12 lg:py-24 ptn-light dark:bg-black dark:ptn-dark">
				{props.children}
			</div>
			<PageFooter {...props.pageFooter} />
		</>
	);
}
