import React from "react";
import { NormalizedState } from "../../../appState/models";
import { Order, OrderState } from "../models/domain";
import { OrderListPageState, OrderViewState } from "./toOrderListProps";
import useOrdersState from "./useOrdersState";
import useProductsState from "./useProductsState";
import { orderActions, productActions } from "../actions";
import printerActions from "../../printer/actions";
import ordersRoutes from "../routes";

interface OrderListState {
	pageState: OrderListPageState;
	orders: Array<Order>;
	ordersViewStates: NormalizedState<OrderViewState>;
	fetchOrders: () => Promise<void>;
	toggleOrder: (orderId: number) => void;
	printOrder: (orderId: number) => Promise<void>;
	prepareOrder: (orderId: number) => Promise<void>;
	approveOrder: (orderId: number) => Promise<void>;
	cancelOrder: (orderId: number) => Promise<void>;
	collectOrder: (orderId: number) => Promise<void>;
}

export default function useOrderListState(): OrderListState {
	const orders = useOrdersState().orders;
	const products = useProductsState().products;
	const orderWithResolvedProducts = resolveOrders(orders);
	const [ordersViewStates, setOrderViewState] = React.useState<NormalizedState<OrderViewState>>({});
	const [pageState, setPageState] = React.useState<OrderListPageState>(OrderListPageState.FETCHING);

	function resolveOrders(unresolvedOrders: Array<Order>): Array<Order> {
		return unresolvedOrders.map((order) => {
			return new Order({
				...order,
				products: products[order.id] || [],
			});
		});
	}

	function fetchOrders(): Promise<void> {
		setPageState(OrderListPageState.FETCHING);
		return orderActions.fetchAllOrders().then(() => {
			setPageState(OrderListPageState.IDLE);
		});
	}

	function setOrderViewStateForOrderId(orderId: number, orderViewState: OrderViewState) {
		setOrderViewState((state) => {
			return {
				...state,
				[orderId]: orderViewState,
			};
		});
	}

	async function expandOrder(orderId: number) {
		setOrderViewStateForOrderId(orderId, OrderViewState.FETCHING);
		if (!products[orderId]) await productActions.fetchAllProductsForOrderId(orderId);
		setOrderViewStateForOrderId(orderId, OrderViewState.EXPANDED);
	}

	function collapseOrder(orderId: number) {
		setOrderViewStateForOrderId(orderId, OrderViewState.COLLAPSED);
	}

	function resolveOrderViewState(orderId: number) {
		if (ordersViewStates[orderId]) return ordersViewStates[orderId];
		setOrderViewStateForOrderId(orderId, OrderViewState.COLLAPSED);
		return OrderViewState.COLLAPSED;
	}

	function toggleOrder(orderId: number) {
		const orderViewState = resolveOrderViewState(orderId);

		switch (orderViewState) {
			case OrderViewState.COLLAPSED:
				return expandOrder(orderId);
			case OrderViewState.EXPANDED:
				return collapseOrder(orderId);
			case OrderViewState.FETCHING:
				return;
		}
	}

	function resolveOrder(orderId: number): Order {
		return orderWithResolvedProducts.find((order) => order.id === orderId)!;
	}

	function resolveProductsForOrder(order: Order): Promise<Order> {
		if (order.products.length > 0) {
			return Promise.resolve(order);
		} else {
			return ordersRoutes.getAllProductsForOrder(order.id).then((products) => {
				return new Order({
					...order,
					products: products,
				});
			});
		}
	}

	function printOrder(orderId: number): Promise<void> {
		const orderToPrint = resolveOrder(orderId);
		return resolveProductsForOrder(orderToPrint).then((orderWithMaybeResolvedProducts) => {
			return printerActions.printOrder(orderWithMaybeResolvedProducts);
		});
	}

	function prepareOrder(orderId: number): Promise<void> {
		const currentOrderViewState = resolveOrderViewState(orderId);
		setOrderViewStateForOrderId(orderId, OrderViewState.FETCHING);
		return orderActions.setOrderState(orderId, OrderState.PREPARED).then(() => {
			setOrderViewStateForOrderId(orderId, currentOrderViewState);
		});
	}

	function approveOrder(orderId: number): Promise<void> {
		const currentOrderViewState = resolveOrderViewState(orderId);
		setOrderViewStateForOrderId(orderId, OrderViewState.FETCHING);
		return orderActions.setOrderState(orderId, OrderState.APPROVED).then(() => {
			setOrderViewStateForOrderId(orderId, currentOrderViewState);
		});
	}

	function cancelOrder(orderId: number): Promise<void> {
		const currentOrderViewState = resolveOrderViewState(orderId);
		setOrderViewStateForOrderId(orderId, OrderViewState.FETCHING);
		return orderActions.setOrderState(orderId, OrderState.CANCELED).then(() => {
			setOrderViewStateForOrderId(orderId, currentOrderViewState);
		});
	}

	function collectOrder(orderId: number): Promise<void> {
		const currentOrderViewState = resolveOrderViewState(orderId);
		setOrderViewStateForOrderId(orderId, OrderViewState.FETCHING);
		return orderActions.setOrderState(orderId, OrderState.COLLECTED).then(() => {
			setOrderViewStateForOrderId(orderId, currentOrderViewState);
		});
	}

	React.useEffect(() => {
		fetchOrders();
	}, []);

	return {
		orders: orderWithResolvedProducts,
		pageState,
		ordersViewStates,
		fetchOrders,
		toggleOrder,
		printOrder,
		prepareOrder,
		approveOrder,
		cancelOrder,
		collectOrder,
	};
}
