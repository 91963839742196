import React from "react";
import {
	isOrderLoadingDetailProps,
	OrderButtonProps,
	OrderDetailProps,
	OrderLoadingDetailProps,
	OrderProps,
} from "./props";
import { ButtonProps, IconButtonProps } from "../../../models/props";
import { Icon, IconType } from "../../../components/icons";
import Menu from "../../../components/menu";
import { Status } from "./status";
import { LoadingOrderDetails, OrderDetails } from "./detailSection";
import { resolveButtons } from "./resolveButtons";

export function Order(props: OrderProps) {
	return (
		<div>
			<div className="bg-gray-100 dark:bg-gray-900 py-2 px-4 rounded-md shadow-md flex items-center space-x-2">
				<Status {...props.status} />
				<span className="min-w-0 px-2 text-lg flex-grow truncate">{props.title}</span>
				<div className="hidden sm:block">
					<div className="flex items-center space-x-2">{resolveButtons(props.buttons)}</div>
				</div>
				<div className="sm:hidden">
					<Menu
						header={({ toggleMenuState }) => {
							return (
								<button onClick={toggleMenuState} className="btn-2 btn-md">
									<span className="sr-only">Bestellungs Menu</span>
									<Icon
										icon={IconType.DOTS_VERTICAL}
										className="w-5 h-5 text-gray-800 dark:text-gray-200"
									/>
								</button>
							);
						}}
						content={({ toggleMenuState }) => {
							return (
								<div className="divide-y divide-gray-200 dark:divide-gray-700">
									{resolveMenuItems(
										Object.entries(props.buttons).reduce(
											(parsedButtonProps, [buttonKey, buttonProps]) => {
												return {
													...parsedButtonProps,
													[buttonKey]: {
														...buttonProps,
														onSelect: () => {
															buttonProps.onSelect();
															toggleMenuState();
														},
													},
												};
											},
											{} as OrderButtonProps
										)
									)}
								</div>
							);
						}}
					/>
				</div>
			</div>
			{props.details && <div className="mt-1">{renderOrderDetails(props.details)}</div>}
		</div>
	);
}

export function renderOrderDetails(props: OrderLoadingDetailProps | OrderDetailProps) {
	if (isOrderLoadingDetailProps(props)) return <LoadingOrderDetails />;
	return <OrderDetails {...props} />;
}

export function resolveMenuItems(buttons: OrderButtonProps) {
	return Object.entries(buttons).map(([buttonName, buttonProps], index) => {
		if (buttonProps === undefined) return null;

		switch (buttonName) {
			case "toggleDetails":
				return (
					<div
						key={index + 1}
						className="text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
					>
						<IconMenuItem {...buttonProps} />
					</div>
				);
			case "approve":
				return (
					<div
						key={index + 1}
						className="text-green-500 dark:text-green-400 hover:bg-green-200 dark:hover:bg-green-800"
					>
						<MenuItem {...buttonProps} />
					</div>
				);
			case "prepare":
				return (
					<div
						key={index + 1}
						className="text-green-500 dark:text-green-400 hover:bg-green-200 dark:hover:bg-green-800"
					>
						<MenuItem {...buttonProps} />
					</div>
				);
			case "collect":
				return (
					<div
						key={index + 1}
						className="text-green-500 dark:text-green-400 hover:bg-green-200 dark:hover:bg-green-800"
					>
						<MenuItem {...buttonProps} />
					</div>
				);
			case "cancel":
				return (
					<div
						key={index + 1}
						className="text-red-500 dark:text-red-400 hover:bg-red-200 dark:hover:bg-red-800"
					>
						<MenuItem {...buttonProps} />
					</div>
				);
			case "print":
				return (
					<div
						key={index + 1}
						className="text-gray-700 dark:text-gray-300 hover:bg-gray-200 dark:hover:bg-gray-700"
					>
						<IconMenuItem {...(buttonProps as IconButtonProps)} />
					</div>
				);
			default:
				return null;
		}
	});
}

function IconMenuItem(props: IconButtonProps) {
	return (
		<button onClick={props.onSelect} className="w-full px-4 py-2 flex items-center space-x-2 text-current">
			<Icon icon={props.icon} className="w-5 h-5" />
			<span>{props.title}</span>
		</button>
	);
}

function MenuItem(props: ButtonProps) {
	return (
		<button onClick={props.onSelect} className="w-full px-4 py-2 text-left text-current">
			{props.title}
		</button>
	);
}
