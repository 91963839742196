import React, { PropsWithChildren } from "react";
import { CustomerProps, OrderDetailProps, OrderMetaProps, ProductProps, ProductsProps } from "./props";

export function DetailSection(props: PropsWithChildren<{ title: string }>) {
	return (
		<div>
			<p className="px-2 border-b border-gray-400 dark:border-gray-600">{props.title}</p>
			<div className="pl-2 py-2">{props.children}</div>
		</div>
	);
}

export function OrderDetailsContainer(props: PropsWithChildren<{}>) {
	return <div className="bg-gray-100 dark:bg-gray-900 py-2 px-4 rounded-md shadow-md">{props.children}</div>;
}

export function OrderDetails(props: OrderDetailProps) {
	return (
		<OrderDetailsContainer>
			<div className="space-y-4">
				<CustomerDetails {...props.customer} />
				<MetaDetails {...props.meta} />
				<Products {...props.products} />
				<Notes notes={props.meta.notes} />
			</div>
		</OrderDetailsContainer>
	);
}

export function LoadingOrderDetails() {
	return (
		<OrderDetailsContainer>
			<div className="space-y-4 animate-pulse">
				<div className="space-y-2">
					<div className="h-6 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-1/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-1/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-1/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
				</div>
				<div className="space-y-2">
					<div className="h-6 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-1/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-1/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-1/3 mr-auto bg-gray-200 dark:bg-gray-800 rounded-md" />
				</div>
				<div className="space-y-2">
					<div className="h-6 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
					<div className="h-6 w-full bg-gray-200 dark:bg-gray-800 rounded-md" />
				</div>
			</div>
		</OrderDetailsContainer>
	);
}

export function CustomerDetails(props: CustomerProps) {
	return (
		<DetailSection title={props.title}>
			<div className="text-gray-600 dark:text-gray-400">
				<p>{props.name}</p>
				<p>{props.phone}</p>
				<p>{props.mail}</p>
			</div>
		</DetailSection>
	);
}

export function MetaDetails(props: OrderMetaProps) {
	return (
		<DetailSection title={props.title}>
			<div className="text-gray-600 dark:text-gray-400">
				<p>{props.status}</p>
				<p>{props.date}</p>
				<p>{props.time}</p>
			</div>
		</DetailSection>
	);
}

export function Notes(props: { notes: string }) {
	return (
		<DetailSection title="Notes">
			<div className="text-gray-600 dark:text-gray-400">
				<p>{props.notes}</p>
			</div>
		</DetailSection>
	);
}

export function Products(props: ProductsProps) {
	return (
		<DetailSection title={props.title}>
			<ul className="divide-y divide-gray-400 dark:divide-gray-600">
				{props.products.map((productProps, index) => {
					return (
						<li key={index + 1} className="py-1">
							<Product {...productProps} />
						</li>
					);
				})}
				<li className="py-1 border-t border-gray-400 text-lg dark:border-gray-600 flex items-center justify-between">
					<p>{props.price.title}</p>
					<p>{props.price.value}</p>
				</li>
			</ul>
		</DetailSection>
	);
}

export function Product(props: ProductProps) {
	return (
		<div className="flex space-x-2 text-gray-600 dark:text-gray-400">
			<span className="flex-grow">{props.title}</span>
			<span className="px-2 border-2 border-gray-400 dark:border-gray-600 rounded">{props.amount}</span>
		</div>
	);
}
