import { Api } from "./models";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

interface AxiosApiDependencies {
	baseUrl: string;
	timeout?: number;
	requestInterceptors: Array<(response: AxiosRequestConfig) => AxiosRequestConfig>;
	responseInterceptors: Array<(response: AxiosResponse) => Promise<any>>;
}

export function createAxiosApi(dependencies: AxiosApiDependencies): Api {
	const instance = axios.create({ baseURL: dependencies.baseUrl, timeout: dependencies.timeout });
	dependencies.requestInterceptors.forEach((requestInterceptor) => {
		instance.interceptors.request.use(requestInterceptor);
	});
	dependencies.responseInterceptors.forEach((responseInterceptor) => {
		instance.interceptors.response.use(responseInterceptor);
	});
	return {
		get: (path, parameter) => instance.get(path, { params: parameter }),
		post: (path, body, parameter) => instance.post(path, body, { params: parameter }),
		xmlPost: (path, body, parameter) => instance.post(path, body, { params: parameter }),
	};
}
