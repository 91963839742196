import * as redux from "redux";
import { authenticationReducer } from "../authentication/reducer/reducer";
import printerReducer from "../features/printer/reducer";
import { settingsReducer } from "../features/settings/reducer/reducer";
import { ordersReducer } from "../features/orders/reducer/ordersReducer";
import { productsReducer } from "../features/orders/reducer/productsReducer";

export default redux.combineReducers({
	authentication: authenticationReducer,
	settings: settingsReducer,
	printer: printerReducer,
	orders: ordersReducer,
	products: productsReducer,
});
