import useSettingsState from "../../settings/selectors/useSettingsState";

interface RequiredSettingsState {
	hasNoMasterPassword: boolean;
	hasNoLocation: boolean;
	hasNoPinCode: boolean;
}

export function useRequiredSettings(): RequiredSettingsState {
	const settingsState = useSettingsState();
	return {
		hasNoMasterPassword: settingsState.masterPasswordResult === undefined,
		hasNoLocation: settingsState.location === undefined,
		hasNoPinCode: settingsState.pinCode === undefined,
	};
}
